import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ApiUrl } from '../../../../services/ApiRest';

export const StatusSelect = ({ status, setstatus }) => {
    const [data, setdata] = useState([]);

    const dataList = async () => {
        await axios.get(ApiUrl + 'status-list')
            .then(resp => {
                resp = resp.data;
                setdata(resp);
                const statusSelected = {
                    "": resp[0].status_id  // Clave vacía con un valor "miValor"
                };
                setstatus(statusSelected)

            })
            .catch(e => {
                console.log(e);
            })
    }

    const message = `El estado actual es: ${status}`;
    // console.log(message);
    const onInputChange = ({ target }) => {
        const { name, value } = target;
        setstatus({
            ...status,
            [name]: value
        })
        console.log("*******status")
        console.log(status)
    }

    useEffect(() => {

        dataList();


    }, [])
    return (
        <select className="form-select" onChange={onInputChange} required aria-label=".form-select-sm example">
            {/* Renderizar la primera opción solo si `data` tiene elementos */}
            {data && data.length > 0 && (
                <option value={data[0].status_id}>{data[0].description}</option>
            )}
            {/* Mapear opciones solo si `data` está definido */}
            {data && data.map((item) => (
                <option key={item.status_id} value={item.status_id}>{item.description}</option>
            ))}
        </select>

    )
}
