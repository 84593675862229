import React, { useState } from 'react'
import { CategorySelect } from '../selects/CategorySelect'
import { SupplierSelect } from '../selects/SupplierSelect'
import { ToastContainer, toast } from 'react-toastify';
import { useGetDate } from '../../../../hooks/useGetDate';
import axios from 'axios';
import { ApiUrl } from '../../../../services/ApiRest';



export const AddProduct = ({ dataList })=> {

  const [data, setdata] = useState([]);
  const [utility, setutility] = useState()

  const [categorySelected, setcategorySelected] = useState();
  const [supplierSelected, setsupplierSelected] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [show_in_web_sell, setshow_in_web_sell] = useState(false)
  const [img1, setimg1] = useState();
  const { year, month, day } = useGetDate();

  // Asegúrate de que `month` y `day` sean números válidos
  if (typeof month !== 'number' || typeof day !== 'number') {
    console.error("Error: `month` o `day` no son números válidos", { month, day });
    // Lanza un error o maneja la situación según tus necesidades
  }

  const paddedMonth = String(month + 1).padStart(2, '0');
  const paddedDay = String(day).padStart(2, '0');
  const currentDate = `${year}-${paddedMonth}-${paddedDay}`;

  const [formD, setformD] = useState({
    description: '',
    buying_price: '',
    min_selling_price: '0',
    selling_price: '',
    rent_price: '0',
    entry_date: currentDate,
    observation: '',
  });

  const toastId = React.useRef(null);
  const notify = () => toastId.current = toast("Cargando Datos...", { autoClose: true, type: toast.TYPE.INFO, position: toast.POSITION.BOTTOM_RIGHT });

  const onInputChange = ({ target }) => {
    const { name, value } = target;
    setformD({
      ...formD,
      [name]: value
    })
  }

  

  const updateImg1 = e => {
    setimg1(e);
  }

  const deleteTable = () => {
    const script1 = document.createElement("script");
    script1.src = `/assets/dataTable/datatable-destroy.js`;
    script1.async = true;
    document.body.appendChild(script1);
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    setButtonDisabled(true);
    notify();
    const f = new FormData();
    f.append("category_id", categorySelected[""]);
    f.append("supplier_id", supplierSelected[""]);
    f.append("description", formD.description);
    f.append("buying_price", formD.buying_price);
    f.append("selling_price", formD.selling_price);
    f.append("min_selling_price", formD.min_selling_price);
    f.append("rent_price", formD.rent_price);
    f.append("entry_date", formD.entry_date);
    f.append("show_in_web_sell", show_in_web_sell);
    if (img1 != undefined) {
      f.append("img", img1[0]);
    }

    if (formD.buying_price > formD.selling_price) {
      setButtonDisabled(false);
      return (toast.error("El precio de compra no pude ser mayor que el precio de venta", { position: toast.POSITION.BOTTOM_RIGHT }))
    }

    const utility = parseFloat((formD.selling_price - formD.buying_price).toFixed(2));
    var porcentaje = parseFloat((100 - ((formD.buying_price * 100) / formD.selling_price)).toFixed(2));
    formD.observation = 'Utilidad: ' + porcentaje + '% = ' + utility;
    f.append("observation", formD.observation);
    // console.log(Object.fromEntries(f));

    await axios.post(ApiUrl + 'product-add', f)
      .then(response => {
        var resp = response.data;
        console.log(resp);
        if (resp.success) {
          toast.success("Producto Agregado exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
          deleteTable();
          if(dataList){
            dataList();
          }
          setformD({
            description: '',
            buying_price: '',
            min_selling_price: '0',
            selling_price: '',
            rent_price: '0',
            entry_date: currentDate,
            observation: ''
          });
          // setcategorySelected('');
          // setsupplierSelected('');
        } else {
          toast.error("El Producto no se ha agregado", { position: toast.POSITION.BOTTOM_RIGHT });
        }
      })
      .catch(e => {
        console.log(e)
        toast.error("" + e + "  !", { position: toast.POSITION.BOTTOM_RIGHT });
      })
      .finally(() => {
        setButtonDisabled(false); // Habilitar el botón después de que la solicitud haya finalizado
      });
  }
  return (
    <div className="modal fade" id="addProductModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel"><b>CREAR PRODUCTO </b></h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form onSubmit={onSubmit}>
              <div className='row'>
                <div className='col-12'>
                  <div className="">
                    <label className="form-label"><b>Descripción</b></label>
                    <input type="text" name='description' className="form-control" value={formD.description} onChange={onInputChange} required ></input>
                  </div>
                </div>

                <div className='col-6 col-md-6'>
                  <div className="">
                    <label className="form-label"><b>Categoría</b></label>
                    <CategorySelect
                      category={categorySelected}
                      setcategory={setcategorySelected}
                    />
                  </div>
                </div>
                <div className='col-6 col-md-6'>
                  <div className="">
                    <label className="form-label"><b>Proveedor</b></label>
                    <SupplierSelect
                      supplier={supplierSelected}
                      setsupplier={setsupplierSelected}
                    />
                  </div>
                </div>
                <div className='col-4 col-md-3'>
                  <div className="">
                    <label className="form-label"><b>P. Compra</b></label>
                    <input type="text" className="form-control" name="buying_price" value={formD.buying_price} onChange={onInputChange} required ></input>
                  </div>
                </div>

                <div className='col-4 col-md-3'>
                  <div className="">
                    <label className="form-label"><b>P. Venta Nuevo</b></label>
                    <input type="text" className="form-control" name="selling_price" value={formD.selling_price} onChange={onInputChange} required></input>
                  </div>
                </div>

                <div className='col-4 col-md-3'>
                  <div className="">
                    <label className="form-label"><b>P. Venta Semi Nuevo</b></label>
                    <input type="text" className="form-control" name='min_selling_price' value={formD.min_selling_price} onChange={onInputChange} required></input>
                  </div>
                </div>

                <div className='col-4 col-md-3'>
                  <div className="">
                    <label className="form-label"><b>P. Alquiler</b></label>
                    <input type="text" className="form-control" name="rent_price" value={formD.rent_price} onChange={onInputChange} required></input>
                  </div>
                </div>
                <div className='col-4 col-md-4'>
                  <div className="">
                    <label className="form-label"><b>Fecha Ingreso</b></label>
                    <input type="date" className="form-control" name='entry_date' value={formD.entry_date} onChange={onInputChange} required></input>
                  </div>
                </div>
                <div className='col-4 col-md-4'>
                  <div className="">
                    <label className="form-label"><b>Imagen (Opcional)</b></label>
                    <input className="form-control" type="file" name="img" onChange={(e) => updateImg1(e.target.files)} id="formFile"></input>
                  </div>
                </div>

                <div className='col-4 col-md-4'>
                  <div className="">
                    <label className="form-label"><b>Mostrar en Web</b></label>

                    <div className="form-check form-switch" style={{ textAlign: 'center' }}>
                      <input className="form-check-input" name='show_in_web_sell' type="checkbox" id="flexSwitchCheckDefault" style={{ fontSize: '1.5rem' }} checked={show_in_web_sell} onChange={() => setshow_in_web_sell(!show_in_web_sell)}></input>
                      <label className="form-check-label" style={{ alignItems: 'center' }}>Mostrar</label>
                    </div>
                  </div>
                </div>

                <div className='col-12 col-sm-12'>
                  <div className="form-group">
                    <label > <b>Observaciones</b></label>
                    <input type="text" name='observation' className="form-control" value={formD.observation} onChange={onInputChange} placeholder=''></input>
                  </div>
                </div>
              </div>

              {/* <button type="submit" className="btn btn-primary">Submit</button> */}
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                <button type="submit" className="btn btn-success" disabled={buttonDisabled}>Guardar</button>
              </div>
            </form>

          </div>

        </div>
      </div>
    </div>
  )
}
