import React from 'react'
import { WhatsappButtonScreen } from '../whatsappButton/WhatsappButtonScreen'
import { FooterScreen } from '../../footer/FooterScreen'
import { Link } from 'react-router-dom'
import { NavBarScreen } from '../../navBar/NavBarScreen'

export const SingleAliance = () => {

    const tours = [
        {
            city: "Penipe, Chimborazo",
            description: "Un acogedor alojamiento rodeado de naturaleza, perfecto para desconectar y disfrutar de la tranquilidad de Penipe. Precios del hospedaje: Habitación individual: 25 USD por noche. Habitación doble: 40 USD por noche. Suite familiar: 70 USD por noche.",
            business_name: "Urkuñan",
            googleMaps: "https://maps.app.goo.gl/8KaKqF3PfaWK6ez57",
            opening_hours: "Abierto las 24 horas, todos los días",
            benefit: "Beneficios para miembros del Tiger Club: 10% de descuento en todas las reservas. Check-out extendido hasta las 2 p.m. Acceso gratuito a eventos especiales organizados por Urkuñan.",
            Facebook: "https://www.facebook.com/profile.php?id=100067891923078",
            Instagram: "https://www.facebook.com/profile.php?id=100067891923078",
            img_1: "/assets/images/carouselTyger/urkunan.jpg",
            img_2: "/assets/images/carouselTyger/urku3.jpg",
            contact_phone: "961119670",
            messagge_for_contact: "Hola, estoy interesado en alojarme en Urkuñan. ¿Podrían darme más información?"
        }
    ];

    return (
        <>
            <NavBarScreen var='.' />
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <div className='container pantalla-grande'>
                <br></br>
                {tours.map((tour, index) => (
                    <div className='row' key={index}>
                        <div className='col-12 col-sm-12 col-md-6'>
                            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item carousel-item2 active">
                                        <img src={`${tour.img_1}`} style={{ width: '100%' }} className="card-img-top" alt="..."></img>
                                    </div>
                                    <div className="carousel-item carousel-item2 ">
                                        <img src={`${tour.img_2}`} style={{ width: '100%' }} className="card-img-top" alt="..."></img>
                                    </div>
                                </div>
                                <button className="carousel-control-prev" benefit="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" benefit="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>


                        </div>
                        <div className='col-12 col-sm-12 col-md-6'>
                            <br></br>
                            <div>

                            </div>
                            <h3 className='camping-letters'> {tour.city}</h3>
                            <h1 className='camping-letters' style={{ textTransform: 'uppercase' }}> {tour.business_name}</h1>
                            <p className='text-justify'>{tour.description}</p>

                            <p><b>Para suscriptores:</b> {tour.benefit}</p>
                            <p><b>Horario de atención:</b> {tour.opening_hours}</p>
                            <p className='text-justify'><b>Dirección:</b> {tour.googleMaps}</p>

            

                            <div style={{ paddingTop: "3vh" }}>
                                <div className='row text-center '>
                                    <div className='  '>
                                        <a className='btn-rs animate__animated animate__fadeInDownBig' href={tour.Facebook} target="_blank" style={{ textDecoration: 'none', padding: '6px', color:'black' }}>
                                            <i className="fab fa-facebook   animate__animated animate__fadeInDownBig "  ></i>
                                        </a>
                                        <a className='btn-rs' href={tour.Instagram} target="_blank" style={{ textDecoration: 'none', padding: '6px', color:'black' }}>
                                            <i className="fab fa-instagram  animate__animated animate__fadeInUpBig"  ></i>
                                        </a>
                                       
                                    </div>

                                </div>

                            </div>



                            <br></br>
                            <div className='container text-center'>
                                <a href={`https://api.whatsapp.com/send?phone=+593${tour.contact_phone}&text=${tour.messagge_for_contact}`} target="_blank" style={{ padding: '5px' }}>
                                    <button benefit="button" className="btn btn-success" style={{ margin: '2vh' }}> CONTACTAR <i class="fab fa-whatsapp"></i> </button >
                                </a>
                                
                                
                                <a href={tour.googleMaps} target="_blank" style={{ padding: '5px' }}>
                                    <button benefit="button" className="btn btn-warning" style={{ margin: '2vh' }}> UBICACIÓN EN MAPA</button >
                                </a>

                               
                            </div>


                        </div>
                    </div>
                ))}
            </div>

            {/* *********PANTALLA PEQUE************* */}

            <div className='container pantalla-peque'>
                <br></br>
                {tours.map((tour, index) => (
                    <div className=' row' key={tour.tour_catalogues_id} >
                        <h1 className='camping-letters text-center' style={{ textTransform: 'uppercase' }}> {tour.business_name}</h1>
                        <h5 className='camping-letters text-center'> {tour.city}</h5>

                        <div className='col-12 col-sm-12 col-md-6'>
                            <div id="carouselExampleControls2" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item carousel-item2 active">
                                        <img src={`${tour.img_1}`} style={{ width: '100%' }} className="card-img-top" alt="..."></img>
                                    </div>
                                    <div className="carousel-item carousel-item2 ">
                                        <img src={`${tour.img_2}`} style={{ width: '100%' }} className="card-img-top" alt="..."></img>
                                    </div>
                                </div>
                                <button className="carousel-control-prev" benefit="button" data-bs-target="#carouselExampleControls2" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" benefit="button" data-bs-target="#carouselExampleControls2" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>


                        </div>
                        <div className='col-12 col-sm-12 col-md-6'>
                            <br></br>
                            <div>

                            </div>

                            <h3 className='camping-letters'> {tour.city}</h3>
                            <h1 className='camping-letters' style={{ textTransform: 'uppercase' }}> {tour.business_name}</h1>
                            <p className='text-justify'>{tour.description}</p>

                            <p><b>Para suscriptores:</b> {tour.benefit}</p>
                            <p><b>Horario de atención:</b> {tour.opening_hours}</p>
                            <p className='text-justify'><b>Dirección:</b> {tour.googleMaps}</p>


                            <div style={{ paddingTop: "3vh" }}>
                                <div className='row text-center '>
                                    <div className='  '>
                                        <a className='btn-rs animate__animated animate__fadeInDownBig' href={tour.Facebook} target="_blank" style={{ textDecoration: 'none', padding: '6px', color:'black' }}>
                                            <i className="fab fa-facebook   animate__animated animate__fadeInDownBig "  ></i>
                                        </a>
                                        <a className='btn-rs' href={tour.Instagram} target="_blank" style={{ textDecoration: 'none', padding: '6px', color:'black' }}>
                                            <i className="fab fa-instagram  animate__animated animate__fadeInUpBig"  ></i>
                                        </a>
                                       
                                    </div>

                                </div>

                            </div>

                            <br></br>
                            <div className='container text-center'>
                                <a href={`https://api.whatsapp.com/send?phone=+593${tour.contact_phone}&text=${tour.messagge_for_contact}`} target="_blank" style={{ padding: '5px' }}>
                                    <button benefit="button" className="btn btn-success" style={{ margin: '2vh' }}> CONTACTAR <i class="fab fa-whatsapp"></i> </button >
                                </a>
                                
                                
                                <a href={tour.googleMaps} target="_blank" style={{ padding: '5px' }}>
                                    <button benefit="button" className="btn btn-warning" style={{ margin: '2vh' }}> UBICACIÓN EN MAPA</button >
                                </a>

                               
                            </div>


                        </div>
                    </div>
                ))}
            </div>


            <br></br>
            <br></br>
            <br></br>
            <WhatsappButtonScreen />
            <FooterScreen />

        </>
    )
}
