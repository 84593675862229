import React, { useEffect, useState } from 'react'
import { ApiUrl } from '../../../services/ApiRest';
import axios from 'axios';
import { useGetDate } from '../../../hooks/useGetDate';
import { ToastContainer, toast } from 'react-toastify';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';


export const ListToBuy = () => {

  const { year, month, day } = useGetDate();
  const paddedMonth = String(month + 1).padStart(2, '0');
  const paddedDay = String(day).padStart(2, '0');
  const currentDate = `${year}-${paddedMonth}-${paddedDay}`;
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [toBuy, settoBuy] = useState([]);
  const [selectedtoBuy, setselectedtoBuy] = useState([]);

  const [formD, setformD] = useState({
    name: '',
    description: '',
    price: '',
  });

  const getData = async () => {
    await axios.get(ApiUrl + "tobuy-list")
      .then(response => {
        const data = response.data;
        settoBuy(data);

        //cargamos los datos nuevos
        const script = document.createElement("script");
        script.src = `/assets/dataTable/dataTable.js`;
        script.async = true;
        document.body.appendChild(script);
      })
      .catch(e => {
        console.log(e)
      })
  }

  const selecttoBuy = (data) => {
    console.log(data)
    setselectedtoBuy(data);
    setformD({
      name: data.name || '',
      price: data.price || '',
      description: data.description || '',
    });
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    setButtonDisabled(true);
    // notify();
    const f = new FormData();

    f.append("name", formD.name);
    f.append("price", formD.price);
    f.append("description", formD.description);
   
    console.log(Object.fromEntries(f));
    await axios.post(ApiUrl + 'tobuy-add', f)
      .then(response => {
        var resp = response.data;
        console.log(resp);
        if (resp.success) {
          toast.success("Producto Agregado exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
          deleteTable();
          getData();
          setformD({
            name: '',
            price: '',
            description: '',
          });
        } else {
          toast.error("El Producto no se ha agregado", { position: toast.POSITION.BOTTOM_RIGHT });
        }
      })
      .catch(e => {
        console.log(e)
        toast.error("" + e + "  !", { position: toast.POSITION.BOTTOM_RIGHT });
      })
      .finally(() => {
        setButtonDisabled(false); // Habilitar el botón después de que la solinametud haya finalizado
      });
  }

  const onSubmitEdit = async (event) => {
    event.preventDefault();
    setButtonDisabled(true);
    // notify();
    const f = new FormData();

    f.append("name", formD.name);
    f.append("price", formD.price);
    f.append("description", formD.description);
    
    console.log(Object.fromEntries(f));
    await axios.post(ApiUrl + 'toBuy-update/' + selectedtoBuy.toBuy_id, f)
      .then(response => {
        var resp = response.data;
        console.log(resp);
        if (resp.success) {
          toast.success("toBuyo Actualizado exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
          deleteTable();
          getData();
          setformD({
            name: '',
            price: '',
            description: '',
          
          });
          // setcategorySelected('');
          // setsupplierSelected('');
        } else {
          toast.error("El crédito no se ha editado", { position: toast.POSITION.BOTTOM_RIGHT });
        }
      })
      .catch(e => {
        console.log(e)
        toast.error("" + e + "  !", { position: toast.POSITION.BOTTOM_RIGHT });
      })
      .finally(() => {
        setButtonDisabled(false); // Habilitar el botón después de que la solinametud haya finalizado
      });
  }

  const clearData= ()=>{
    setformD({
      name: '',
      price: '',
      description: '',
    
    });
  }

  const onInputChange = ({ target }) => {
    const { name, value } = target;
    setformD({
      ...formD,
      [name]: value
    })
  }

  const deletetoBuy = async () => {
    console.log(selectedtoBuy)
    await axios.post(ApiUrl + 'tobuy-delete/' + selectedtoBuy.id_ToBuy)
      .then(resp => {
        deleteTable();
        getData();
        setformD({
          name: '',
          price: '',
          description: '',
        
        });
        toast.success("Crédito Eliminado exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });

      })
      .catch(e => {
        console.log(e);
      })
  }


  const deleteTable = () => {
    const script1 = document.createElement("script");
    script1.src = `/assets/dataTable/datatable-destroy.js`;
    script1.async = true;
    document.body.appendChild(script1);
  }

  useEffect(() => {
    getData();

  }, [])
  return (
    <div>
      <div className='row'>
        <div className='col-12 '>
          <div className="card">
            <div className="card-header">
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <b>LISTA DE PRODUCTOS POR COMPRAR</b>
                </div>
                <div className='col-12 col-md-6 ' style={{ textAlign: 'right' }}>
                  <button className='btn btn-success btn-sm' data-bs-toggle="modal" data-bs-target="#addtoBuyModal" >
                    <b>AGREGAR PRODUCTO A COMPRAR </b>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body table-responsive">
              <table className='table table-hover' id="dataTable" style={{ width: '100%' }} >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nombre del Producto</th>
                    <th>Precio</th>
                    <th>Fecha de creación</th>
                    <th>Descripción</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {toBuy.map((toBuy, index) => (
                    <tr key={toBuy.id_ToBuy}>
                      <td>{index + 1}</td>
                      <td>{toBuy.name}</td>
                      <td>{toBuy.price}</td>
                      <td>{toBuy.created_at}</td>
                      <td>{toBuy.description}</td>
                      <td>

                        {/* <button className='btn btn-outline-primary' data-bs-toggle="modal" data-bs-target="#edittoBuyModal" onClick={() => selecttoBuy(toBuy)}><i className="fas fa-pen" aria-hidden="true"></i></button> */}

                        <button className='btn btn-outline-danger' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => selecttoBuy(toBuy)}><DeleteOutlinedIcon /></button>

                      </td>

                    </tr>
                  ))}

                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Eliminar</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                Está seguro que desea eliminar el producto a comprar <b>{selectedtoBuy.name}</b><br></br>
                Con el valor <b>{selectedtoBuy.price}</b><br></br><br></br>
                {selectedtoBuy.description}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => deletetoBuy()} data-bs-dismiss="modal" >Aceptar</button>
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => clearData()}   >Cancelar</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <ToastContainer theme="colored" /> */}
      <div className="modal fade" id="addtoBuyModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"><b>CREAR PRODUCTO A COMPRAR </b></h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form onSubmit={onSubmit}>
                <div className='row'>
                  <div className='col-6 col-md-8'>
                    <div className="">
                      <label className="form-label">Nombre del producto</label>
                      <input type="text" name='name' className="form-control" value={formD.name} onChange={onInputChange}  ></input>
                    </div>
                  </div>
                  <div className='col-4 col-md-4'>
                    <div className="">
                      <label className="form-label">Precio Estimado</label>
                      <input type="text" className="form-control" name="price" value={formD.price} onChange={onInputChange} required ></input>
                    </div>
                  </div>
                  
                  <div className='col-12 col-md-12'>
                    <div className="">
                      <label className="form-label">Observación</label>
                      <input type="text" name='description' className="form-control" value={formD.description} onChange={onInputChange}  ></input>

                    </div>
                  </div>
                </div>

                {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                  <button type="submit" className="btn btn-success" disabled={buttonDisabled}>Guardar</button>
                </div>
              </form>

            </div>

          </div>
        </div>
      </div>


      <div className="modal fade" id="edittoBuyModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"><b>EDITAR CRÉDITO </b></h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form onSubmit={onSubmitEdit}>
                <div className='row'>
                  <div className='col-6 col-md-6'>
                    <div className="">
                      <label className="form-label">Cédula</label>
                      <input type="text" name='name' className="form-control" value={formD.name || ''} onChange={onInputChange}  ></input>
                    </div>
                  </div>

                  <div className='col-6 col-md-6'>
                    <div className="">
                      <label className="form-label">Nombre y Apellido</label>
                      <input type="text" name='price' className="form-control" value={formD.price || ''} onChange={onInputChange} required ></input>

                    </div>
                  </div>
                  <div className='col-12 col-md-12'>
                    <div className="">
                      <label className="form-label">Observación</label>
                      <input type="text" name='description' className="form-control" value={formD.description || ''} onChange={onInputChange}  ></input>

                    </div>
                  </div>
                  <div className='col-4 col-md-3'>
                    <div className="">
                      <label className="form-label">Total</label>
                      <input type="text" className="form-control" name="price" value={formD.price || ''} onChange={onInputChange} required ></input>
                    </div>
                  </div>

                  <div className='col-4 col-md-3'>
                    <div className="">
                      <label className="form-label">Estado de crédito</label>
                      <select className="form-select" name='state' onChange={onInputChange} value={formD.state || ''} required aria-label=".form-select-sm example">
                        <option value="10" >No Pagado</option>
                        <option value="11" >Pagado</option>
                      </select>
                      {/* <input type="text" className="form-control" name="selling_price" value={formD.selling_price} onChange={onInputChange} required></input> */}
                    </div>
                  </div>

                  <div className='col-4 col-md-3'>
                    <div className="">
                      <label className="form-label">Fecha de crédito</label>
                      <input type="date" className="form-control" name='limit_date' value={formD.limit_date || ''} onChange={onInputChange} required></input>
                    </div>
                  </div>

                  <div className='col-12 col-sm-12'>
                    <div className="form-group">
                      <label > Observanameones</label>
                      <input type="text" name='observation' className="form-control" value={formD.observation || ''} onChange={onInputChange} placeholder=''></input>
                    </div>
                  </div>
                </div>

                {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                  <button type="submit" className="btn btn-success" disabled={buttonDisabled}>Guardar</button>
                </div>
              </form>

            </div>

          </div>
        </div>
      </div>

      <ToastContainer theme="colored" />


    </div>
  )
}
