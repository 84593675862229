import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ApiUrl } from '../../../services/ApiRest';
import { ToastContainer, toast } from 'react-toastify';
import { WarehouseSelect } from './selects/WarehouseSelect';
import { StatusSelect } from './selects/StatusSelect';
import { ProductsSelect } from './selects/ProductsSelect';
import { DeleteProduct } from './DeleteProduct';
import { AddProduct } from './products/AddProduct';

export const InventoryList = () => {
    const [data, setdata] = useState([]);
    const [productSelected, setproductSelected] = useState([]);
    const [warehouseSelected, setwarehouseSelected] = useState();

    const [optionSelected, setoptionSelected] = useState()

    // USESTATES FOR PRODUCTS TO ADD
    const [productToAddSelected, setproductToAddSelected] = useState();
    const [statusSelected, setstatusSelected] = useState([])
    const [quantityAdd, setquantityAdd] = useState()
    const [observationAdd, setobservationAdd] = useState()
    const [inventoryId, setinventoryId] = useState()
    const [dataWarehouse, setdataWarehouse] = useState([]);
    const [productsTotalQuantity, setproductsTotalQuantity] = useState();
    const [productsWithoutWarehouse, setproductsWithoutWarehouse] = useState();
    const [productsNameWarehouse, setproductsNameWarehouse] = useState('');
    const [productsStatusWarehouse, setproductsStatusWarehouse] = useState('');
    const [warehouseId, setwarehouseId] = useState()
    const [totalInventory, settotalInventory] = useState(0);
    const [inventoriesId, setinventoriesId] = useState()
    const [reloadChild, setReloadChild] = useState(false);
    const [resetSelect, setResetSelect] = useState(false);
    const [productToDelete, setProductToDelete] = useState(null);
    const [inventoryInWarehouse, setinventoryInWarehouse] = useState()
    const [inventoryInWarehouse2, setinventoryInWarehouse2] = useState()

    const [reloadProducts, setreloadProducts] = useState(false)

    const [formD, setformD] = useState({
        quantityToMove: '',
        observation: '',
        warehouse_options: 1,
    });

    const dataList = async () => {
        await axios.get(ApiUrl + 'inventory-list')
            .then(resp => {
                resp = resp.data;
                setdata(resp);
                console.log("Todos los productos de INVENTARIO:", resp);
                const newTotal = resp.reduce((acc, data) => acc + parseFloat(data.total_price), 0);
                settotalInventory(newTotal);
                const script = document.createElement("script");
                script.src = `/assets/dataTable/dataTable.js`;
                script.async = true;
                document.body.appendChild(script);
            })
            .catch(e => {
                console.log(e);
            })
    }

    const dataListProductsQuantity = async () => {
        try {
            const response = await axios.get(ApiUrl + `inventorie-quiantity-in-warehouse/${inventoryId}`);
            const responseData = response.data;
            setdataWarehouse(responseData);
            console.log("INformacion Productos BODEGAS:", responseData);
            if (responseData.length > 0 && responseData[0].warehouse_id !== undefined) {
                console.log("BODEGAS ID:", responseData[0].warehouse_id);
                setwarehouseId(responseData[0].warehouse_id);
            } else {
                console.log("NO HAY VALOR QUE MOSTRAR BODEGAS ID");
            }

        } catch (error) {
            console.error(error);
        }
    }

    const onInputChange = ({ target }) => {
        const { name, value } = target;
        setformD({
            ...formD,
            [name]: value
        })

        // setobservationAdd(...observationAdd);
    }

    const selectProduct = (data, opc) => {
        setproductSelected(data);
        if (opc == 1) {
            console.log('opc1')
            setoptionSelected(1);
        } else {
            console.log('opc2')
            setoptionSelected(2);
        }
    }

    const onSubmit = async (event) => {
        event.preventDefault();

        let quantityToMove = formD.quantityToMove;
        let available = productSelected.stock - productSelected.inWarehouse;
        console.log(available);
        console.log(quantityToMove);
        if (quantityToMove > available || quantityToMove < 0) {
            toast.error("La cantidad ingresada debe ser menor o igual a la cantidad disponible y mayor que 0", { position: toast.POSITION.BOTTOM_RIGHT });
        } else {
            const f = new FormData();

            f.append("inventories_id", productSelected.inventories_id);
            f.append("product_id", productSelected.product_id);
            f.append("warehouse_id", warehouseSelected[""]);
            f.append("quantityToMove", formD.quantityToMove);
            f.append("observation", formD.observation);
            f.append("product_status_id", productSelected.status_id);


            console.log(Object.fromEntries(f));

            await axios.post(ApiUrl + 'productsWarehouse-add', f)
                .then(response => {
                    var resp = response.data;
                    console.log(resp);
                    if (resp.success) {

                        toast.success(resp.messagge, { position: toast.POSITION.BOTTOM_RIGHT });
                        deleteTable();
                        dataList();
                        setformD({
                            quantityToMove: '',
                            observation: '',
                        });

                    } else {
                        toast.error(resp.messagge, { position: toast.POSITION.BOTTOM_RIGHT });
                    }
                })
                .catch(e => {
                    console.log(e)
                    toast.error("" + e + "  !", { position: toast.POSITION.BOTTOM_RIGHT });

                })
        }
    }


    async function onSubmitAddToInventory(event) {
        event.preventDefault();
        console.log('VALOR PRODUCTo::', productToAddSelected.value);
        if (formD.quantityToMove <= 0) {
            toast.error("La cantidad ingresada debe ser mayor a 0", { position: toast.POSITION.BOTTOM_RIGHT });
        } else {
            const f = new FormData();
            f.append("product_id", productToAddSelected.value);
            f.append("stock", formD.quantityToMove);
            f.append("status_id", statusSelected[""]);
            f.append("Observation", formD.observation);
            // f.append("warehouse_id", formD.warehouse_options);
            f.append("warehouse_id", formD.warehouse_options || '1');
            // console.log(Object.fromEntries(f));
            console.log('Datos enviados al backend AGREGADOSSSS en INVentorIES:', Object.fromEntries(f));
            await axios.post(ApiUrl + 'inventory-add', f)
                .then(response => {
                    var resp = response.data;
                    console.log('TODA LAS TUPLAS:', resp);
                    // setinventoriesId(resp.inventories_id);                
                    console.log('INENTORIES IDDD 2:', resp.inventories_id);
                    if (resp.success) {
                        toast.success(resp.messagge, { position: toast.POSITION.BOTTOM_RIGHT });
                        setReloadChild(!reloadChild);
                        dataList();
                        deleteTable();
                        setformD({
                            ...formD,
                            quantityToMove: '', // Limpiar la cantidad después de agregar el producto //classList
                            observation: '',
                            
                        });
                        setResetSelect(true);
                        setResetSelect(false);
                    } else {
                        toast.error(resp.messagge, { position: toast.POSITION.BOTTOM_RIGHT });
                    }

                    const inventoriesIdToUse = resp.inventories_id; // Utilizamos el ID que recibimos en la respuesta
                    console.log('Q VALOR HAY AQUI:', inventoriesIdToUse);
                    console.log('Nuevo valor de VOdegas:', formD.warehouse_options);
                    const formDa = new FormData();
                    // formDa.append("warehouse_id", formD.warehouse_options);
                    formDa.append("warehouse_id", formD.warehouse_options || '1');
                    formDa.append("inventories_id", inventoriesIdToUse);
                    formDa.append("quantity", formD.quantityToMove);
                    console.log('Datos enviados al backend AGREGADOSSSS Product Warehouse:', Object.fromEntries(formDa));
                    axios.post(ApiUrl + 'productsWarehouse-add-to-warehouse', formDa)
                        .then(response => {
                            var resp = response.data;
                            console.log('TODA LAS TUPLAS PRODUCTS WAREHOUSE:', resp);
                            console.log('INENTORIES IDDD 2:', resp.warehouse_id);
                            if (resp.success) {
                                toast.success(resp.messagge, { position: toast.POSITION.BOTTOM_RIGHT });
                                dataList();
                                deleteTable();
                            } else {
                                // toast.error(resp.messagge, { position: toast.POSITION.BOTTOM_RIGHT });
                                console.log(resp.messagge1);
                            }
                        })
                        .catch(e => {
                            console.log(e);
                            toast.error("" + e + "  !", { position: toast.POSITION.BOTTOM_RIGHT });
                        });


                })
                .catch(e => {
                    console.log(e);
                    toast.error("" + e + "  !", { position: toast.POSITION.BOTTOM_RIGHT });
                });
        }
    }

    const onDataListUpdateAdm = () => {
        console.log("Actualización de datos solicitada");
    };

    const onSubmitEdit = async (event) => {
        event.preventDefault();
        console.log(productSelected.inventories_id)
        console.log(formD.quantityToMove)
        console.log(statusSelected[""])
        console.log(formD.observation)

        if (formD.quantityToMove < 0) {
            toast.error("La cantidad ingresada debe ser mayor a 0", { position: toast.POSITION.BOTTOM_RIGHT });
        } else {
            const f = new FormData();

            f.append("inventories_id", productSelected.inventories_id);
            f.append("quantity", formD.quantityToMove);
            f.append("status_id", statusSelected[""]);
            f.append("Observation", formD.observation);

            // console.log(Object.fromEntries(f));

            await axios.post(ApiUrl + 'inventory-edit', f)
                .then(response => {
                    var resp = response.data;
                    console.log(resp);
                    if (resp.success) {
                        toast.success(resp.messagge, { position: toast.POSITION.BOTTOM_RIGHT });
                        deleteTable();
                        dataList();

                    } else {
                        toast.error(resp.messagge, { position: toast.POSITION.BOTTOM_RIGHT });
                    }
                })
                .catch(e => {
                    console.log(e)
                    toast.error("" + e + "  !", { position: toast.POSITION.BOTTOM_RIGHT });

                })

        }
    }

    const deleteTable = () => {
        const script1 = document.createElement("script");
        script1.src = `/assets/dataTable/datatable-destroy.js`;
        script1.async = true;
        document.body.appendChild(script1);
    }



    const modalDeleteNoticeNegation = (inventoryInWarehouse1) => {
        console.log('La advertencia:', inventoryInWarehouse1);
        if (inventoryInWarehouse != 0 || inventoryInWarehouse != '' || inventoryInWarehouse != null) {
            toast.error("No se puede eliminar el producto ya que el producto está presente en una o más bodegas.", { position: toast.POSITION.BOTTOM_RIGHT });
        }
    }

    useEffect(() => {
        dataList();
    }, [])

    useEffect(() => {
        if (inventoryId) {
            dataListProductsQuantity();
        }
    }, [inventoryId]);

    return (
        <div>
            <div className='row'>
                <div className='col-12 '>
                    <div className="card">
                        <div className=" card-header">
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <b>INVENTARIO</b>
                                </div>
                                <div className='col-12 col-md-6 ' style={{ textAlign: 'right' }}>
                                    <button className='btn btn-success btn-sm' data-bs-toggle="modal" data-bs-target="#addProductsToInventory" onClick={() => setreloadProducts(!reloadProducts)}><b>AGREGAR PRODUCTO</b> <i className="fa fa-plus" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <center><h4> TOTAL INVERTIDO: {totalInventory} </h4></center>
                            </div>
                        </div>
                        <div className="card-body table-responsive">
                            <table className='table table-hover' id="dataTable-ord-col1" >
                                <thead>
                                    <tr>
                                        {/* <th>ID Prod</th> */}
                                        <th>Cant</th>
                                        <th>Producto</th>
                                        <th>Proveedor</th>
                                        <th>Estado</th>
                                        <th>Categoría</th>
                                        <th>En Bodega</th>
                                        <th>Sin Bodega</th>
                                        <th>PU. Compra</th>
                                        <th>P. Total</th>
                                        <th>PU. Venta</th>
                                        
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((data, i) => (
                                        // <tr class="alert alert-primary" role="alert" style={{ backgroundColor:  data.status_id === 5 ?'red':'white'}} key={data.inventories_id}>
                                        <tr className={`
                                            ${data.status_id == 5 ? 'text-danger' : ''
                                            // data.status_id == 4 ? 'text-warning' : ''
                                            }
                                            `} key={data.inventories_id}>
                                            {/* <td>{data.product_id}</td> */}
                                            <td>{data.stock}</td>
                                            {/* <td>{ textLimit(data.description,20)}</td> */}
                                            <td >{data.product}</td>
                                            <td >{data.name_store}</td>
                                            <td ><span className={`
                                                ${data.status_id == 5 ? 'badge rounded-pill bg-danger' :
                                                    data.status_id == 4 ? 'badge rounded-pill bg-warning' : ''
                                                }
                                                `}>{data.status} </span></td>
                                            <td>{data.category}</td>
                                            <td>{data.inWarehouse}</td>
                                            <td><span className={`
                                                ${data.withoutWarehouse > 0 ? 'badge rounded-pill bg-danger' : ''
                                                }
                                                `} style={{ paddingRight: '0.5vw', paddingLeft: '0.5vw' }}>{data.withoutWarehouse}</span></td>
                                            <td>{data.unit_price_product}</td>
                                            <td>{data.total_price}</td>
                                            <td>{data.unit_selling_price}</td>
                                            <td>
                                                <button className='btn btn-outline-success btn-sm' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => selectProduct(data, 1)} ><i className="fas fa-share-square"></i></button>
                                                <button className='btn btn-outline-secondary btn-sm' data-bs-toggle="modal" data-bs-target="#editModal" onClick={() => selectProduct(data, 2)} ><i className="fas fa-edit"></i></button>
                                                <button className='btn btn-outline-primary btn-sm' data-bs-toggle="modal" data-bs-target="#exampleModalView" onClick={() => { setinventoryId(data.inventories_id); dataListProductsQuantity(); setproductsTotalQuantity(data.stock); setproductsWithoutWarehouse(data.withoutWarehouse); setproductsNameWarehouse(data.product); setproductsStatusWarehouse(data.status); }} ><i className="fas fa-eye" ></i></button>
                                                {
                                                    data.inWarehouse === 0 ? (
                                                        <button className='btn btn-outline-danger btn-sm' data-bs-toggle="modal" data-bs-target="#exampleModalDelete" onClick={() => { setProductToDelete(data.inventories_id); console.log("Valor INVENT IDD:", data.inventories_id); setinventoryInWarehouse(data.inWarehouse); console.log('IN WAREHOUSE:', data.inWarehouse); }}><i className="fas fa-trash-alt" aria-hidden="true"></i></button>
                                                    ) : (
                                                        <button className='btn btn-outline-danger btn-sm' onClick={() => { modalDeleteNoticeNegation(data.inWarehouse); }}><i className="fas fa-trash-alt" aria-hidden="true"></i></button>
                                                    )
                                                }

                                            </td>
                                        </tr>
                                    ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* MOVER A BODEGAS */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"><b>Mover a Bodega</b></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div style={{ textAlign: 'center' }}>
                                <b>{productSelected.product}</b><br></br>
                                <label className="form-label">Sin Asignar: {productSelected.stock - productSelected.inWarehouse - formD.quantityToMove} </label>

                            </div>
                            <br></br>
                            <form onSubmit={onSubmit}>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className="">
                                            <label className="form-label">Productos que desea mover</label>
                                            {/* <input type="text" name='quantity' className="form-control" value={formD.quantity} onChange={onInputChange} required ></input> */}
                                            <input type="number" name='quantityToMove' className="form-control" value={formD.quantityToMove} onChange={onInputChange} placeholder='Ejemplo: 5' required ></input>

                                        </div>
                                    </div>

                                    <div className='col-6 col-md-6'>
                                        <div className="">
                                            <label className="form-label">Bodega</label>
                                            <WarehouseSelect
                                                warehouse={warehouseSelected}
                                                setwarehouse={setwarehouseSelected}
                                            />
                                        </div>
                                    </div>


                                    <div className='col-12'>
                                        <div className="form-group">
                                            <label >Observaciones</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1" name="observation" value={formD.observation} onChange={onInputChange} rows="3"></textarea>
                                        </div>
                                    </div>

                                </div>

                                {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                    <button type="submit" className="btn btn-success" data-bs-dismiss="modal">Guardar</button>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            {/* <button type="button" className="btn btn-primary" onClick={() => deleteTour()} data-bs-dismiss="modal"  >Aceptar</button> */}
                            {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  >Cancelar</button> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* MODAL DE AGREGAR PRODUCTOS A INVENTARIO */}
            <div className="modal fade" id="addProductsToInventory" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"><b>AGREGAR PRODUCTOS A INVENTARIO</b></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div style={{ textAlign: 'center' }}>
                                {/* <b>{productSelected.product}</b><br></br> */}
                                {/* <label className="form-label">Sin Asignar: {productSelected.stock - productSelected.inWarehouse} </label> */}

                            </div>
                            <br></br>
                            <form onSubmit={onSubmitAddToInventory}>
                                <div className='row'>
                                    <label className="form-label">Seleccione Producto</label>
                                    <div className='col-11'>
                                        <div className="">
                                            <ProductsSelect
                                                productSelect={productToAddSelected}
                                                setproductSelect={setproductToAddSelected}
                                                resetValue={resetSelect}
                                                onDataListUpdat2={onDataListUpdateAdm}
                                                reload={reloadChild}
                                                reloadProducts={reloadProducts}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-1'>
                                        <button className='btn btn-success btn-sm' data-bs-toggle="modal" data-bs-target="#addProductModal" ><b></b><i className="fa fa-plus" aria-hidden="true"></i></button>

                                    </div>


                                    <div className='col-6 col-md-6'>
                                        <div className="">
                                            <label className="form-label">Cantidad</label>
                                            {/* <input type="text" name='quantity' className="form-control" value={formD.quantity} onChange={onInputChange} required ></input> */}
                                            <input type="number" name='quantityToMove' className="form-control" value={formD.quantityToMove} onChange={onInputChange} placeholder='Ejemplo: 5' required ></input>
                                            {/* <input type="number" name='quantityToMove' className="form-control" value={formD.quantityToMove} onChange={onInputChange} placeholder='000' required ></input> */}
                                        </div>
                                    </div>
                                    <div className='col-6 col-md-6'>
                                        <div className="">
                                            <label className="form-label">Estado</label>
                                            <StatusSelect
                                                status={statusSelected}
                                                setstatus={setstatusSelected}
                                            // resetValue={resetSelect}
                                            // reload={reloadChild}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-12'>
                                        <div className="form-group">
                                            <label >Observaciones</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1" name="observation" value={formD.observation} onChange={onInputChange} rows="3"></textarea>
                                        </div>
                                    </div>
                                    <select className="form-select" name='warehouse_options' value={formD.warehouse_options} onChange={onInputChange} aria-label="Default select example">
                                        <option value="1">Bodega para venta</option>
                                        <option value="2">Bodega Dario</option>
                                        <option value="3">Bodega Jhon</option>
                                        <option value="4">Bodega Luis</option>
                                        <option value="5">Bodega Alimentación</option>
                                        {/* <option value="">Sin bodega</option> */}
                                    </select>
                                </div>

                                {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                    {/* <button type="submit" className="btn btn-success" onClick={() => setResetSelect(false)} >Guardar</button> */}
                                    <button type="submit" className="btn btn-success" onClick={() => setResetSelect(false)}> Añadir </button>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            {/* <button type="button" className="btn btn-primary" onClick={() => deleteTour()} data-bs-dismiss="modal"  >Aceptar</button> */}
                            {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  >Cancelar</button> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* EDIT MODAL */}
            <div className="modal fade" id="editModal" tabIndex="-1" aria-labelledby="editModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editModal"><b>Editar Estado de producto sin bodega</b></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div style={{ textAlign: 'center' }}>
                                <b>{productSelected.product}</b><br></br>
                                <label className="form-label">Sin Bodega: {productSelected.stock - productSelected.inWarehouse - formD.quantityToMove} </label>

                            </div>
                            <br></br>
                            <form onSubmit={onSubmitEdit}>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className="">
                                            <label className="form-label">Productos que desea editar</label>
                                            {/* <input type="text" name='quantity' className="form-control" value={formD.quantity} onChange={onInputChange} required ></input> */}
                                            <input type="number" name='quantityToMove' className="form-control" value={formD.quantityToMove} onChange={onInputChange} placeholder='Ejemplo: 5' required ></input>

                                        </div>
                                    </div>

                                    <div className='col-6 col-md-6'>
                                        <div className="">
                                            <label className="form-label">Estado a Asignar</label>
                                            <StatusSelect
                                                status={statusSelected}
                                                setstatus={setstatusSelected}
                                            />
                                        </div>
                                    </div>


                                    <div className='col-12'>
                                        <div className="form-group">
                                            <label >Observaciones</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1" name="observation" value={formD.observation} onChange={onInputChange} rows="3"></textarea>
                                        </div>
                                    </div>

                                </div>

                                {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                    <button type="submit" className="btn btn-success" data-bs-dismiss="modal">Guardar</button>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            {/* <button type="button" className="btn btn-primary" onClick={() => deleteTour()} data-bs-dismiss="modal"  >Aceptar</button> */}
                            {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  >Cancelar</button> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* INFORMACION DE BODEGAS */}
            <div className="modal fade" id="exampleModalView" aria-labelledby="exampleModal" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Información de productos en Bodegas</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <center><h4> {productsNameWarehouse} </h4></center>
                                    </div>
                                </div>
                                <br></br>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <center><h5> CANTIDAD TOTAL: {productsTotalQuantity} </h5></center>
                                    </div>
                                    <div className='col-sm-4'>
                                        <center><h5>SIN BODEGA: {productsWithoutWarehouse} </h5></center>
                                    </div>
                                    <div className='col-sm-4'>
                                        <center><h5>ESTADO: {productsStatusWarehouse} </h5></center>
                                    </div>
                                </div>
                                <br></br>
                                {
                                    productsWithoutWarehouse === productsTotalQuantity ? (
                                        <center><h5>Producto sin asignar a Bodegas</h5></center>

                                    ) : (
                                        <div className="modal-body text-center">
                                            <div className="card-body table-responsive">
                                                <table className='table table-hover' id="dataTable-ord-col1">
                                                    <thead>
                                                        <tr>
                                                            <th>Cant</th>
                                                            <th>Bodega</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dataWarehouse.map((item) => (
                                                            <tr key={item.product_warehouses_id}>
                                                                <td>{item.quantity}</td>
                                                                <td>{item.description}</td>
                                                            </tr>
                                                        ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )
                                }


                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  >Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ELIMINAR MODAL */}
            <DeleteProduct productToDelete={productToDelete} dataList={dataList} />

            {/*CREAR PRODUCTO*/}
            <AddProduct />

            <ToastContainer theme="colored" />
        </div>
    )
}
