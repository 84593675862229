import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ApiStorage, ApiUrl } from '../../../services/ApiRest';
import { ToastContainer, toast } from 'react-toastify';
import { CategorySelect } from './selects/CategorySelect';
import { SupplierSelect } from './selects/SupplierSelect';
import { useGetDate } from '../../../hooks/useGetDate';
import { AddProduct } from './products/AddProduct';

export const ListProduct = () => {
    const [data, setdata] = useState([]);
    const [img1, setimg1] = useState();
    const [utility, setutility] = useState()
    const [selectedImage, setselectedImage] = useState([]);
    const { year, month, day } = useGetDate();
    const paddedMonth = String(month + 1).padStart(2, '0');
    const paddedDay = String(day).padStart(2, '0');
    const currentDate = `${year}-${paddedMonth}-${paddedDay}`;
    // const currentDate = year + '-' + (month + 1) + '-' + day;
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [show_in_web_sell, setshow_in_web_sell] = useState(false)
    const [formD, setformD] = useState({
        description: '',
        buying_price: '',
        min_selling_price: '0',
        selling_price: '',
        rent_price: '0',
        entry_date: currentDate,
        observation: '',
    });


    const [reloadCategory, setreloadCategory] = useState(false)
    const [reloadSupplier, setreloadSupplier] = useState(false)
    const [categorySelected, setcategorySelected] = useState();
    const [supplierSelected, setsupplierSelected] = useState();
    const [productSelected, setproductSelected] = useState({
        description: '',
        buying_price: '',
        min_selling_price: '',
        selling_price: '',
        rent_price: '',
        entry_date: currentDate,
        observation: '',
    });

    const dataList = async () => {
        await axios.get(ApiUrl + 'product-list')
            .then(resp => {
                resp = resp.data;
                setdata(resp);
                // Inicializar contador de productos con porcentaje válido
                let validProductsCount = 0;

                // Reducir para calcular el porcentaje total
                const totalPercentage = resp.reduce((acc, product) => {
                    // Comprobar si observation existe y tiene el formato correcto
                    if (product.observation && product.observation.match(/(\d+(\.\d+)?)%/)) {
                        // Extraer el número del porcentaje de la cadena "Utilidad: XX% = YY"
                        const percentage = parseFloat(product.observation.match(/(\d+(\.\d+)?)%/)[1]);
                        validProductsCount++;  // Aumentar el contador de productos válidos
                        // console.log(validProductsCount)
                        return acc + percentage;
                    }
                    return acc; // Si no tiene un porcentaje válido, no sumar nada
                }, 0);

                // Calcular el promedio SOLO usando los productos con porcentaje válido
                const avgPercentage = (totalPercentage / validProductsCount).toFixed(2);  // Redondear a 2 decimales
                setutility(avgPercentage);
                //cargamos los datos nuevos
                const script = document.createElement("script");
                script.src = `/assets/dataTable/dataTable.js`;
                script.async = true;
                document.body.appendChild(script);
            })
            .catch(e => {
                console.log(e);
            })
    }

    const deleteTable = () => {
        const script1 = document.createElement("script");
        script1.src = `/assets/dataTable/datatable-destroy.js`;
        script1.async = true;
        document.body.appendChild(script1);
    }



    const onInputChangeEdit = ({ target }) => {
        const { name, value } = target;
        setproductSelected({
            ...productSelected,
            [name]: value
        })
    }

    const updateImg1 = e => {
        setimg1(e);
    }



    const onSubmitEdit = async (event) => {
        event.preventDefault();
        setButtonDisabled(true);
        notify();
        const f = new FormData();
        if (!categorySelected[""]) {
            f.append("category_id", categorySelected.category_id);
        } else {
            f.append("category_id", categorySelected[""]);
        }
        if (!supplierSelected[""]) {
            f.append("supplier_id", supplierSelected.supplier_id);
        } else {
            f.append("supplier_id", supplierSelected[""]);
        }
        f.append("description", productSelected.description);
        f.append("buying_price", productSelected.buying_price);
        f.append("min_selling_price", productSelected.min_selling_price);
        f.append("selling_price", productSelected.selling_price);
        f.append("rent_price", productSelected.rent_price);
        f.append("entry_date", productSelected.entry_date);
        f.append("show_in_web_sell", show_in_web_sell);
        if (img1 != undefined) {
            f.append("img", img1[0]);
        }

        const utility = parseFloat((productSelected.selling_price - productSelected.buying_price).toFixed(2));
        var porcentaje = parseFloat((100 - ((productSelected.buying_price * 100) / productSelected.selling_price)).toFixed(2));

        productSelected.observation = 'Utilidad: ' + porcentaje + '% = ' + utility;
        f.append("observation", productSelected.observation);
        // console.log("Ediiit")
        // console.log(Object.fromEntries(f));
        await axios.post(ApiUrl + 'product-update/' + productSelected.product_id, f)
            .then(response => {
                var resp = response.data;
                console.log(resp);
                if (resp.success) {
                    toast.success("Producto Actualizado exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
                    deleteTable();
                    dataList();
                    setformD({
                        description: '',
                        buying_price: '',
                        min_selling_price: '0',
                        selling_price: '',
                        rent_price: '0',
                        entry_date: currentDate,
                        observation: ''
                    });
                    setshow_in_web_sell(false);
                    // setcategorySelected('');
                    // setsupplierSelected('');
                } else {
                    toast.error("El producto no se ha Actualizado", { position: toast.POSITION.BOTTOM_RIGHT });
                }
            })
            .catch(e => {
                console.log(e)
                toast.error("" + e + "  !", { position: toast.POSITION.BOTTOM_RIGHT });
            })
            .finally(() => {
                setButtonDisabled(false); // Habilitar el botón después de que la solicitud haya finalizado
            });
    }

    const toastId = React.useRef(null);
    const notify = () => toastId.current = toast("Cargando Datos...", { autoClose: true, type: toast.TYPE.INFO, position: toast.POSITION.BOTTOM_RIGHT });
    const success = (messagge) => toast.success("Registro agregado exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });

    const selectProduct = (data) => {
        console.log(data.show_in_web_sell)
        if (data.show_in_web_sell == 'true') {
            setshow_in_web_sell(true)
        } else {
            setshow_in_web_sell(false)
        }

        setproductSelected(data);
        setcategorySelected(data)
        setsupplierSelected(data);
        setreloadCategory(true)
        setreloadSupplier(true)
    }

    const deleteTour = async () => {
        await axios.post(ApiUrl + 'product-delete/' + productSelected.product_id)
            .then(resp => {
                toast.success("Producto eliminado exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
                deleteTable();
                dataList();
            })
            .catch(e => {
                console.log(e);
            })
    }

    useEffect(() => {
        dataList();
    }, [])

    return (
        <div>
            <div className='row'>
                <div className='col-12 '>
                    <div className="card">
                        <div className=" card-header">
                            <div className='row'>
                                <div className='col-12 col-md-4'>
                                    <b>LISTA DE PRODUCTOS</b>
                                </div>
                                <div className='col-12 col-md-4'>
                                    <b>Utilidad Promedio: </b>{utility}%
                                </div>
                                <div className='col-12 col-md-4 ' style={{ textAlign: 'right' }}>
                                    <button className='btn btn-success btn-sm' data-bs-toggle="modal" data-bs-target="#addProductModal" ><b>CREAR PRODUCTO </b><i className="fa fa-plus" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body table-responsive">
                            <table className='table table-hover ' id="dataTable"  >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Descripción</th>
                                        <th>Categoría</th>
                                        <th>Proovedor</th>
                                        <th>PC</th>
                                        <th>PV</th>
                                        <th>PVMin</th>
                                        <th>PA</th>
                                        <th>Observación</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((data, i) => (
                                        <tr key={data.product_id}>
                                            <td>{i + 1}</td>
                                            <td>{data.description}</td>
                                            <td>{data.category}</td>
                                            <td>{data.supplier}</td>
                                            <td>{data.buying_price}</td>
                                            <td>{data.selling_price}</td>
                                            <td>{data.min_selling_price}</td>
                                            <td>{data.rent_price}</td>
                                            <td>{data.observation}</td>
                                            <td>
                                                <button className='btn btn-outline-primary btn-sm' data-bs-toggle="modal" data-bs-target="#watchModal" onClick={() => setselectedImage(data.img)}><i className="fas fa-eye" aria-hidden="true"></i></button>
                                                <button className='btn btn-outline-secondary btn-sm' data-bs-toggle="modal" data-bs-target="#editProductModal" onClick={() => selectProduct(data)}><i className="fas fa-pen" aria-hidden="true"></i></button>
                                                <button className='btn btn-outline-danger btn-sm' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => selectProduct(data)}><i className="fas fa-trash-alt" aria-hidden="true"></i></button>
                                            </td>
                                        </tr>
                                    ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* ELIMINAR PRODUCTO MODAL */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Eliminar</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Está seguro que desea eliminar <b>{productSelected.description}</b>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={() => deleteTour()} data-bs-dismiss="modal"  >Aceptar</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  >Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* VER IMAGEN DE PRODUCTO MODAL */}
            <div className="modal fade" id="watchModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">  <b>{productSelected.description}</b></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">
                            {
                                (selectedImage) ? <img src={`${ApiStorage + selectedImage}`} style={{ width: '100%', padding: '1.5vh' }} className="card-img-top" alt="..."></img>
                                    : <p>Cargando Imagen...</p>
                            }
                        </div>
                        <div className="modal-footer">
                            {/* <button type="button" className="btn btn-primary" onClick={() => deleteTour()} data-bs-dismiss="modal"  >Aceptar</button> */}
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* CREAR PRODUCTO MODAL */}
            <AddProduct dataList={dataList} />


            {/* EDITAR PRODUCTO MODAL */}
            <div className="modal fade" id="editProductModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"><b>EDITAR PRODUCTO</b></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={onSubmitEdit}>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="">
                                            <label className="form-label">Descripción</label>
                                            <input type="text" name='description' className="form-control" value={productSelected.description} onChange={onInputChangeEdit} required ></input>
                                        </div>
                                    </div>

                                    <div className='col-6 col-md-6'>
                                        <div className="">
                                            <label className="form-label">Categoría</label>

                                            <CategorySelect
                                                category={categorySelected}
                                                setcategory={setcategorySelected}
                                                reload={reloadCategory}
                                                setreload={setreloadCategory}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-6 col-md-6'>
                                        <div className="">
                                            <label className="form-label">Proveedor</label>
                                            <SupplierSelect
                                                supplier={supplierSelected}
                                                setsupplier={setsupplierSelected}
                                                reload={reloadSupplier}
                                                setreload={setreloadSupplier}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 col-md-3'>
                                        <div className="">
                                            <label className="form-label">P. Compra</label>
                                            <input type="text" className="form-control" name="buying_price" value={productSelected.buying_price} onChange={onInputChangeEdit} required ></input>
                                        </div>
                                    </div>

                                    <div className='col-4 col-md-3'>
                                        <div className="">
                                            <label className="form-label">P. Venta Nuevo</label>
                                            <input type="text" className="form-control" name="selling_price" value={productSelected.selling_price} onChange={onInputChangeEdit} required></input>
                                        </div>
                                    </div>

                                    <div className='col-4 col-md-3'>
                                        <div className="">
                                            <label className="form-label">P. Venta Semi Nuevo</label>
                                            <input type="text" className="form-control" name='min_selling_price' value={productSelected.min_selling_price} onChange={onInputChangeEdit} required></input>
                                        </div>
                                    </div>

                                    <div className='col-4 col-md-3'>
                                        <div className="">
                                            <label className="form-label">P. Alquiler</label>
                                            <input type="text" className="form-control" name="rent_price" value={productSelected.rent_price} onChange={onInputChangeEdit} required></input>
                                        </div>
                                    </div>
                                    <div className='col-4 col-md-4'>
                                        <div className="">
                                            <label className="form-label">Fecha Ingreso</label>
                                            <input type="date" className="form-control" name='entry_date' value={productSelected.entry_date} onChange={onInputChangeEdit} required></input>
                                        </div>
                                    </div>
                                    <div className='col-4 col-md-4'>
                                        <div className="">
                                            <label className="form-label">Imagen (Opcional)</label>
                                            <input className="form-control" type="file" name="img" onChange={(e) => updateImg1(e.target.files)} id="formFile"></input>
                                        </div>
                                    </div>

                                    <div className='col-4 col-md-4'>
                                        <div className="">
                                            <label className="form-label"><b>Mostrar en Web</b></label>
                                            <div className="form-check form-switch" style={{ textAlign: 'center' }}>
                                                <input
                                                    className="form-check-input"
                                                    name="show_in_web_sell"
                                                    type="checkbox"
                                                    id="flexSwitchCheckDefault"
                                                    style={{ fontSize: '1.5rem' }}
                                                    checked={show_in_web_sell === true}
                                                    onChange={() => setshow_in_web_sell(!show_in_web_sell)}
                                                />
                                                <label className="form-check-label" style={{ alignItems: 'center' }}>Mostrar</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-12 col-sm-12'>
                                        <div className="form-group">
                                            <label > Observaciones</label>
                                            <input type="text" name='observation' className="form-control" value={productSelected.observation !== null && productSelected.observation !== undefined ? productSelected.observation : ''} onChange={onInputChangeEdit} placeholder=''></input>
                                            {/* <input type="text" className="form-control" name='observetion' value={productSelected.observation} onChange={onInputChangeEdit} required></input> */}
                                        </div>
                                    </div>
                                </div>

                                {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                    <button type="submit" className="btn btn-success" disabled={buttonDisabled} data-bs-dismiss="modal">Guardar</button>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            </div>
            <ToastContainer theme="colored" />
        </div>
    )
}
