import React, { useEffect, useState } from 'react'
// import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { ApiStorage, ApiUrl } from '../../../services/ApiRest';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

export const TransactionPassengerList = () => {

    const { idList } = useParams(); // Obtiene el parámetro de la URL (en este caso, '2')
    const [isLoading, setisLoading] = useState(true);
    const [dataTransactions, setdataTransactions] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [transactionId, settransactionId] = useState();
    const [dataTour, setdataTour] = useState({});
    const [formData, setformData] = useState({
        transactions_id: null,
        quantity: '',
        description: '',
        unit_price: '',
        total_price: '',
        movement_type: 'Egreso',
        payment_type: 'Efectivo', // Valor predeterminado
        list_id: idList || '', // Usa el ID de la URL como list_id
    });

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setformData((prevData) => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setformData((prevData) => {
            const updatedData = {
                ...prevData,
                [name]: value,
            };
            // Realiza la multiplicación si ambos valores son válidos
            if (updatedData.quantity && updatedData.unit_price) {
                const quantity = parseFloat(updatedData.quantity) || 0;
                const unitPrice = parseFloat(updatedData.unit_price) || 0;
                // Calcula el precio total
                updatedData.total_price = (quantity * unitPrice).toFixed(2); // Formatea con 2 decimales
            } else {
                updatedData.total_price = ''; // Resetea si falta algún dato
            }
            return updatedData;
        });
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        setButtonDisabled(true);
        try {
            const f = new FormData();
            f.append("quantity", formData.quantity);
            f.append("description", formData.description);
            f.append("unit_price", formData.unit_price);
            f.append("total_price", formData.total_price);
            f.append("movement_type", formData.movement_type);
            f.append("payment_type", formData.payment_type);
            f.append("list_id", idList);
            console.log('Datos enviados al backend AGREGADOSSSS secuential_bill NUEVO CLIENT:', Object.fromEntries(f));
            const response = await axios.post(ApiUrl + 'transaction-add', f);
            const resp = response.data;
            console.log('RESPUESTA DEL BACKEND', response);
            setformData({
                quantity: '',
                description: '',
                unit_price: '',
                total_price: '',
                movement_type: 'Egreso',
                payment_type: 'Efectivo',
                list_id: idList || '',
            });
            if (resp.success) {
                dataListTransactions(); // Vuelve a cargar los datos desde el servidor
            } else {
                toast.error("Transacción errónea", { position: toast.POSITION.BOTTOM_RIGHT });
            }
        } catch (error) {
            console.log("Error al enviar los datos:", error);
            toast.error("" + error + "!", { position: toast.POSITION.BOTTOM_RIGHT });
        } finally {
            setButtonDisabled(false); // Habilitar el botón después de que la solicitud haya finalizado
        };
    };

    const dataListTransactions = async () => {
        try {
            const response = await axios.get(ApiUrl + `transaction-list/${idList}`);
            const responseData = response.data;
            console.log('DATOS:', responseData);
            // setdataTransactions(responseData);
            if (Array.isArray(responseData)) {
                setdataTransactions(responseData);
            } else {
                console.error("La respuesta no es un arreglo:", responseData);
                setdataTransactions([]); // Evita errores si la API devuelve algo inesperado
            }
            const script = document.createElement("script");
            script.src = `/assets/dataTable/dataTable.js`;
            script.async = true;
            document.body.appendChild(script);
            setisLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const onSubmitUpdate = async (event) => {
        event.preventDefault();
        setButtonDisabled(true);
        const f = new FormData();
        f.append("quantity", formData.quantity);
        f.append("description", formData.description);
        f.append("unit_price", formData.unit_price);
        f.append("total_price", formData.total_price);
        f.append("movement_type", formData.movement_type);
        f.append("payment_type", formData.payment_type);
        // f.append("list_id", idList);
        try {
            const response = await axios.post(ApiUrl + `transaction-update/${formData.transactions_id}`, f);
            const resp = response.data;
            if (resp.success) {
                toast.success("Transacción actualizada exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
                dataListTransactions();
            } else {
                toast.error("Transacción no se ha actualizado", { position: toast.POSITION.BOTTOM_RIGHT });
            }
            setformData({
                quantity: '',
                description: '',
                unit_price: '',
                total_price: '',
                movement_type: 'Egreso',
                payment_type: 'Efectivo',
                list_id: idList || '',
            });
        } catch (error) {
            console.log(error);
            toast.error("" + error + "!", { position: toast.POSITION.BOTTOM_RIGHT });
        } finally {
            setButtonDisabled(false); // Habilitar el botón después de que la solicitud haya finalizado
        };
    };

    const deleteTransactions = async (transactions_id) => {
        try {
            const response = await axios.post(ApiUrl + `transaction-delete/${transactions_id}`);
            const resp = response.data;
            if (resp.success === true) {
                toast.success("Transacción eliminada exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
                dataListTransactions();
            } else {
                toast.error("Transacción no eliminada", { position: toast.POSITION.BOTTOM_RIGHT });
            }
        } catch (error) {
            console.log(error);
            toast.error("" + error + "  !", { position: toast.POSITION.BOTTOM_RIGHT });
        }
    };

    const loadDataTour = async () => {
        setisLoading(true);
        try {
            const resp = await axios.get(ApiUrl + 'monthly-tour-show-id-for-list1/' + idList);
            setisLoading(false);
            setdataTour(resp.data[0]);
            console.log('RESP:', resp);
            console.log('resp.data[0]111:', resp.data[0]);            
        } catch (error) {
            console.error("Error en loadDataTour:", error);
            setisLoading(false);
        }
    }

    // // Cálculos de totales
    // const totalIngreso = dataTransactions.filter(item => item.movement_type === 'Ingreso').length;
    // const totalEgreso = dataTransactions.filter(item => item.movement_type === 'Egreso').length;
    // Cálculos de totales
    const totalIngreso = dataTransactions
        .filter(item => item.movement_type === 'Ingreso' && item.payment_type === 'Efectivo')
        .reduce((acc, item) => acc + parseFloat(item.total_price || 0), 0);

    const totalEgreso = dataTransactions
        .filter(item => item.movement_type === 'Egreso' && item.payment_type === 'Efectivo')
        .reduce((acc, item) => acc + parseFloat(item.total_price || 0), 0);

    const dineroEfectivo = totalIngreso - totalEgreso;

    const dineroTransferencia = dataTransactions
        .filter(item => item.movement_type === 'Ingreso' && item.payment_type === 'Transferencia')
        .reduce((acc, item) => acc + parseFloat(item.total_price || 0), 0);

    useEffect(() => {
        dataListTransactions();
    }, [])

    useEffect(() => {
        loadDataTour();
    }, [])

    if (isLoading) {
        return (
            <div>
                <h5>Cargando Datos...</h5>
            </div>
        )
    }

    return (
        <>
            <div>
                <Link to="../passenger-available-tour"><button type="button" className="btn btn-secondary" style={{ marginBottom: "1vh" }}>Regresar</button><br></br></Link>
                <div className='row'>
                    <div className='col-12 '>
                        <div className="card">
                            <div className="card-header">
                                <div className='col-12 col-md-12'>
                                    <b> TRANSACCIONES - Ruta: {dataTour.Description} / {new Date(dataTour.created_at).toLocaleDateString('en-CA')} </b>
                                </div>
                            </div>
                            <br></br>
                            <div className='row'>
                                <div className='col-3 col-md-3'>
                                    <center><h5>Total ingreso: {totalIngreso.toFixed(2)}</h5></center>
                                </div>
                                <div className='col-3 col-md-3'>
                                    <center><h5>Total egreso: {totalEgreso.toFixed(2)}</h5></center>
                                </div>
                                <div className='col-3 col-md-3'>
                                    <center><h5>Dinero efectivo: {dineroEfectivo.toFixed(2)}</h5></center>
                                </div>
                                <div className='col-3 col-md-3'>
                                    <center><h5>Dinero tranferencia: {dineroTransferencia.toFixed(2)}</h5></center>
                                </div>
                            </div>
                            <br></br>
                            <center><button type="button" className="btn btn-success" style={{ marginBottom: "1vh" }} data-bs-toggle="modal" data-bs-target="#addTransaction" >Agregar cobro / gasto</button></center>
                            <br></br>
                            <div className="card-body table-responsive">
                                <table className='table table-hover' id="dataTable" >
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Cantidad</th>
                                            <th>Descripción</th>
                                            <th>P. unitario</th>
                                            <th>P. total</th>
                                            <th>Tipo gasto</th>
                                            <th>Tipo pago</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Array.isArray(dataTransactions) && dataTransactions.map((item, index) => (
                                                <tr key={item.transactions_id}>
                                                    <td >{item.date}</td>
                                                    <td >{item.quantity}</td>
                                                    <td >{item.description}</td>
                                                    <td>{item.unit_price}</td>
                                                    <td>{item.total_price}</td>
                                                    <td>{item.movement_type}</td>
                                                    <td>{item.payment_type}</td>
                                                    <td>
                                                        <button className='btn btn-outline-primary ' data-bs-toggle="modal" data-bs-target="#addTransactionEdit" onClick={() => { setformData({ transactions_id: item.transactions_id, quantity: item.quantity, description: item.description, unit_price: item.unit_price, total_price: item.total_price, movement_type: item.movement_type || "", payment_type: item.payment_type || "" }); }}><i className="fa fa-edit"></i></button>
                                                        <button className='btn btn-outline-danger' data-bs-toggle="modal" data-bs-target="#transactionDelete" onClick={() => { settransactionId(item.transactions_id) }}><i className="fas fa-trash-alt"></i></button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MODAL AGREGAR TRANSACCIONES */}
                <div className="modal fade" id="addTransaction" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={onSubmit}>
                                <div className="modal-header">
                                    <p className="modal-title" id="exampleModalLabel"><b>AGREGAR COMPRA / GASTO</b></p>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-6 col-sm-2'>
                                                <div className="form-group">
                                                    <label >Cantidad</label>
                                                    <input type="text" name='quantity' className="form-control" placeholder='' value={formData.quantity}
                                                        onChange={handleChange} required></input>
                                                </div>
                                            </div>
                                            <div className='col-6 col-sm-10'>
                                                <div className="form-group">
                                                    <label >Descripción</label>
                                                    <input type="text" name='description' className="form-control" placeholder='' value={formData.description}
                                                        onChange={handleChange} required></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <div className="form-group">
                                                    <label > Precio unitario</label>
                                                    <input type="text" name='unit_price' className="form-control" placeholder='' value={formData.unit_price}
                                                        onChange={handleChange} required ></input>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <div className="form-group">
                                                    <label >Precio total</label>
                                                    <input type="text" name='total_price' className="form-control" placeholder='' value={formData.total_price}
                                                        onChange={handleChange} required readOnly disabled></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <div className="form-group">
                                                    <label >Tipo movimiento</label>
                                                    <select className="form-select" name='movement_type' aria-label="Default select example" value={formData.movement_type}
                                                        onChange={handleChange}>
                                                        <option value="Ingreso">Ingreso</option>
                                                        <option value="Egreso">Egreso</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <div className="form-group">
                                                    <label >Tipo pago</label>
                                                    <select className="form-select" name='payment_type' aria-label="Default select example" value={formData.payment_type}
                                                        onChange={handleChange}>
                                                        <option value="Efectivo">Efectivo</option>
                                                        <option value="Transferencia">Transferencia</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary" disabled={buttonDisabled}> Aceptar</button> &nbsp;
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* EDITAR TRANSACCIONES */}
                <div className="modal fade" id="addTransactionEdit" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel"><b>EDITAR TRANSACCIÓN</b></h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={onSubmitUpdate}>
                                    <div className="modal-body">
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-6 col-sm-2'>
                                                    <div className="form-group">
                                                        <label >Cantidad</label>
                                                        <input type="text" name='quantity' className="form-control" value={formData.quantity} onChange={handleChange} required ></input>
                                                    </div>
                                                </div>
                                                <div className='col-6 col-sm-10'>
                                                    <div className="form-group">
                                                        <label >Descripción</label>
                                                        <input type="text" name='description' className="form-control" placeholder='' value={formData.description}
                                                            onChange={handleChange} required></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12 col-sm-6'>
                                                    <div className="form-group">
                                                        <label > Precio unitario</label>
                                                        <input type="text" name='unit_price' className="form-control" placeholder='' value={formData.unit_price}
                                                            onChange={handleChange} required ></input>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-sm-6'>
                                                    <div className="form-group">
                                                        <label >Precio total</label>
                                                        <input type="text" name='total_price' className="form-control" placeholder='' value={formData.total_price}
                                                            onChange={handleChange} required readOnly disabled></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12 col-sm-6'>
                                                    <div className="form-group">
                                                        <label >Tipo movimiento</label>
                                                        <select className="form-select" name='movement_type' aria-label="Default select example" value={formData.movement_type}
                                                            onChange={handleChange}>
                                                            <option value="Ingreso">Ingreso</option>
                                                            <option value="Egreso">Egreso</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-sm-6'>
                                                    <div className="form-group">
                                                        <label >Tipo pago</label>
                                                        <select className="form-select" name='payment_type' aria-label="Default select example" value={formData.payment_type}
                                                            onChange={handleChange}>
                                                            <option value="Efectivo">Efectivo</option>
                                                            <option value="Transferencia">Transferencia</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="submit" className="btn btn-primary" disabled={buttonDisabled} data-bs-dismiss="modal"> Aceptar</button> &nbsp;
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ELIMINAR TRASACTIONS */}
                <div className="modal fade" id="transactionDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Eliminar Transacción</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                ¿Está seguro que desea eliminar
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteTransactions(transactionId)} >Aceptar</button>
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer theme="colored" />
            </div >
        </>
    )
}
