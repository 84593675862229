import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ApiUrl } from '../../../services/ApiRest';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie/es6';
// import { Alert } from 'bootstrap';
const cookies = new Cookies();
const userId = cookies.get('uid');

export const SalesHistory = () => {

    const [total, setTotal] = useState(0);
    const [data, setdata] = useState([]);
    const [total2, setTotal2] = useState(0);
    const [totalFree, setTotalFree] = useState(0);
    const [data2, setdata2] = useState([]);
    const [dataFree, setdataFree] = useState([]);
    const [showForm, setShowForm] = useState(true);
    const [showForm1, setShowForm1] = useState(false);
    const [ci_total, setciTotal] = useState();
    const [secuentialBillIdTotal, setsecuentialBillIdTotal] = useState();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [filteredTotal, setFilteredTotal] = useState(0);
    const [typeSaleTotal, settypeSaleTotal] = useState();

    // const addScript = (src) => {
    //     const script = document.createElement("script");
    //     script.src = src;
    //     script.async = true;
    //     document.body.appendChild(script);
    // };


    // const deleteTable = () => {
    //     const existingScript = document.querySelector('script[src="/assets/dataTable/datatable-destroy.js"]');
    //     if (existingScript) {
    //         existingScript.parentNode.removeChild(existingScript);
    //     }
    //     addScript('/assets/dataTable/datatable-destroy.js');
    // };

    // const dataListTotalSale = async () => {
    //     try {
    //         const response = await axios.get(ApiUrl + `total-list-sale-products-history`);
    //         const responseData = response.data;
    //         setdata2(responseData);
    //         console.log('DATALIST LISTADO dataListTotalSale:', responseData);

    //         // Calcular la suma total
    //         // const newTotal2 = responseData.reduce((acc, item) => acc + parseFloat(item.total_price), 0);
    //         // setTotal2(newTotal2);
    //         const newTotal2 = responseData.reduce((acc, item) => acc + parseFloat(item.total_price), 0).toFixed(2);
    //         setTotal2(newTotal2);
    //         const script = document.createElement("script");
    //         script.src = `/assets/dataTable/dataTable.js`;
    //         script.async = true;
    //         document.body.appendChild(script);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };
    /******************************************************************************************************* */
    // const dataListTotalSale = async () => {
    //     try {
    //         // const response = await axios.get(ApiUrl + `total-list-sale-products-history`, {
    //         const response = await axios.post(ApiUrl + `total-list-sale-products-history2`);
    //         console.log('startEND:');
    //         const responseData = response.data;
    //         // // let responseData = response.data;
    //         console.log('Response Data dataListTotalSale:', responseData);

    //         // // Asegúrate de que responseData sea un arreglo
    //         const validResponseData = Array.isArray(responseData) ? responseData : [];
    //         setdata2(validResponseData);
    //         setFilteredData(validResponseData);
    //         const newTotal2 = validResponseData.reduce((acc, item) => acc + parseFloat(item.total_price), 0).toFixed(2);
    //         setTotal2(newTotal2);
    //         // Destruir cualquier instancia anterior de DataTables antes de volver a inicializarla
    //         deleteTable();           
    //         const script = document.createElement("script");
    //         script.src = `/assets/dataTable/dataTable.js`;
    //         script.async = true;
    //         document.body.appendChild(script);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };
    /******************************************************************************************************* */
    // const loadScript = (src) => {
    //     const script = document.createElement('script');
    //     script.src = src;
    //     script.async = true;
    //     script.onload = () => console.log(`Script ${src} loaded`);
    //     script.onerror = () => console.error(`Failed to load script ${src}`);
    //     document.body.appendChild(script);
    // };
    /******************************************************************************************************* */
    const dataListTotalSale = async () => {
        try {
            // const response = await axios.get(ApiUrl + `total-list-sale-products-history`, {
            const response = await axios.post(ApiUrl + `total-list-sale-products-history2`);
            console.log('startEND:');
            const responseData = response.data;
            // // let responseData = response.data;
            console.log('Response Data dataListTotalSale:', responseData);

            // // Asegúrate de que responseData sea un arreglo
            const validResponseData = Array.isArray(responseData) ? responseData : [];
            setdata2(validResponseData);
            setFilteredData(validResponseData);
            const newTotal2 = validResponseData.reduce((acc, item) => acc + parseFloat(item.total_price), 0).toFixed(2);
            setTotal2(newTotal2);
            // Destruir cualquier instancia anterior de DataTables antes de volver a inicializarla
            // deleteTable();
          

        } catch (error) {
            console.error(error);
        }
    };
    /******************************************************************************************************* */
    // const handleSearch = () => {
    //     if (startDate && endDate) {
    //         alert('ESTOY EN FILTERed');
    //         console.log('VAlor en HandleSearch startDate:', startDate);
    //         console.log('VAlor en HandleSearch endDate:', endDate);
    //         const filtered = data2.filter(item => {
    //             const saleDate = new Date(item.sale_date);
    //             return saleDate >= new Date(startDate) && saleDate <= new Date(endDate);
    //         });
    //         console.log('filtered',filtered);
    //         setFilteredData(filtered);
    //         console.log('filtered2:', filtered);
    //         // Calcular el total de los registros filtrados
    //         const newFilteredTotal = filtered.reduce((acc, item) => acc + parseFloat(item.total_price), 0).toFixed(2);
    //         setFilteredTotal(newFilteredTotal);
    //         setShowForm(false);
    //         setShowForm1(true);
    //     } else {
    //         setFilteredData(data2);
    //     }
    // };
    /******************************************************************************************************* */
    const handleSearch = async (event) => {
        event.preventDefault();
        // alert('DAVID PC');
        const formData = new FormData();
        formData.append("startDate", startDate);
        formData.append("endDate", endDate);
        console.log('Datos enviados al backend AGREGADOSSSS SEARCH DATE:', Object.fromEntries(formData));
        try {
            // const responseSearch = await axios.post(ApiUrl + 'total-list-sale-products-history', formData);
            const responseSearch = await axios.post(ApiUrl + 'total-list-sale-products-history2', formData);
            const respSearch = responseSearch.data;
            // setFilteredData(respSearch);
            // Actualiza el estado con los datos devueltos
            setFilteredData(respSearch);
            console.log('Valor de respSearch:', respSearch);
            // Calcular el total de los registros filtrados
            const newFilteredTotal = respSearch.reduce((acc, item) => acc + parseFloat(item.total_price), 0).toFixed(2);
            setFilteredTotal(newFilteredTotal);
            setShowForm(false);
            setShowForm1(true);
        } catch (error) {
            console.log(error);
            toast.error("" + error + "!", { position: toast.POSITION.BOTTOM_RIGHT });
        }
    };
    /******************************************************************************************************* */
    const dataList = async (ci, secuentialBillId) => {
        try {
            console.log('ci cicicici DATALIST:', ci);
            console.log('ci secuential_bill_id_total DATALIST:', secuentialBillId);

            if (ci && secuentialBillId) {
                const response = await axios.get(ApiUrl + `sale-products-list-total/${ci}/${secuentialBillId}`);
                const responseData = response.data;
                setdata(responseData);
                console.log('DATALIST LISTADO SaleProduct FINAL TOTAL:', responseData);

                const newTotal = responseData.reduce((acc, item) => acc + parseFloat(item.total_price_sale), 0);
                setTotal(newTotal);
                console.log('el total', newTotal);
            } else {
                console.log('En espera de datos');
            }

        } catch (error) {
            console.error(error);
        }
    };

    const dataListFree = async (ci, secuentialBillId) => {
        try {
            console.log('ci cicicici DATALIST:', ci);
            console.log('ci secuential_bill_id_total DATALIST:', secuentialBillId);

            if (ci && secuentialBillId) {
                const response = await axios.get(ApiUrl + `free-sale-products-list-total/${ci}/${secuentialBillId}`);
                const responseData = response.data;
                setdataFree(responseData);
                console.log('DATALIST LISTADO SaleProduct FINAL TOTAL FREE:', responseData);

                const newTotalFree = responseData.reduce((acc, item) => acc + parseFloat(item.price_sale_free), 0);
                setTotalFree(newTotalFree);
            } else {
                console.log('En espera de datos');
            }

        } catch (error) {
            console.error(error);
        }
    };

    const DeleteSaleTotal = async (ci, secuentialBillId) => {
        // Realizar la actualización del estado en el servidor
        console.log('Valor de ciInput ELIMINAR MODAL:', ci_total);
        console.log('Valor de secuentialBillId ELIMINAR MODAL:', secuentialBillIdTotal);
        console.log('Valor de typeSaleTotal ELIMINAR MODAL:', typeSaleTotal);
        try {
            // const response = await axios.post(ApiUrl + `sale-products-delete-total/${ci_total}/${secuentialBillIdTotal}`);
            // const responseData = response.data;            
            const response = await axios.post(ApiUrl + `sale-products-delete-total/${ci_total}/${secuentialBillIdTotal}`);
            const responseData = response.data;
            console.log('DELETE TOTAL', responseData);
            toast.success("Venta eliminada con éxito", { position: toast.POSITION.BOTTOM_RIGHT });
            dataListTotalSale();
        } catch (error) {
            console.error(error);
            toast.error("Error al generar venta", { position: toast.POSITION.BOTTOM_RIGHT });
        }
    };

    const handleShowAll = () => {
        // Limpiar los inputs
        setStartDate('');
        setEndDate('');
        // Mostrar todos los registros
        setFilteredData(data2);
        setFilteredTotal(total2); // Restablecer el total al total general
    };

    const deleteTable = () => {
        const script1 = document.createElement("script");
        script1.src = `/assets/dataTable/datatable-destroy.js`;
        script1.async = true;
        document.body.appendChild(script1);
    }

    useEffect(() => {
        dataListTotalSale();
    }, []);

    useEffect(() => {
        dataList();
    }, []);

    useEffect(() => {
        dataListFree();
    }, []);

    return (
        <div>
            <div className='row'>
                <div className='col-12 '>
                    <div className="card">
                        <div className="card-header">
                            HISTORIAL DE VENTAS
                        </div>
                    </div>
                    <br></br>
                    <div className="d-flex justify-content">
                        <Link to={'../ventas-productos'}>
                            <button type="button" className="btn btn-secondary btn-lg">Ir a ventas</button>&nbsp;
                        </Link>
                    </div>
                    <br></br>
                    <div className="d-flex justify-content"></div>
                    {showForm && (
                        <div className='row'>
                            <div className='col-sm-12'>
                                <center><h4><b> TOTAL VENTAS: {total2} </b></h4></center>
                            </div>
                        </div>
                    )}
                    {showForm1 && (
                        <div className='row'>
                            <div className='col-sm-12'>
                                <center><h4><b> TOTAL VENTAS: {filteredTotal} </b></h4></center>
                            </div>
                        </div>
                    )}
                    <div className='row mb-3'>
                        <div className='col-md-4 d-flex align-items-end'>
                            <div className="form-group me-2 w-100">
                                <label className="me-2">Fecha Inicio</label>
                                <input
                                    type="date"
                                    name='startDate'
                                    className="form-control input-full-width"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className='col-md-4 d-flex align-items-end'>
                            <div className="form-group me-2 w-100">
                                <label className="me-2">Fecha Fin</label>
                                <input
                                    type="date"
                                    className="form-control input-full-width"
                                    name='endDate'
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className='col-md-4 d-flex align-items-end'>
                            <div className="d-flex w-100">
                                <button type="button" className="btn btn-success me-2" onClick={handleSearch} style={{ marginBottom: '16px' }}>Buscar</button>
                                <button type="button" className="btn btn-success" onClick={handleShowAll} style={{ marginBottom: '16px' }}>Mostrar todo</button>
                            </div>
                        </div>
                    </div>

                    <br></br>
                    <div className="card-body">
                        <hr style={{ margin: '10px 0' }} />
                        <div className="card-body table-responsive">
                            <table className='table table-hover ' id="dataTable1-ord-col1"  >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nombre del cliente</th>
                                        <th>Cédula del cliente</th>
                                        <th>Tipo de venta</th>
                                        <th>Total de venta</th>
                                        <th>Fecha</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map((item, index) => (
                                        <tr key={item.sales_products_id}>
                                            <td>{index + 1}</td>
                                            <td>{item.passenge_name}</td>
                                            <td>{item.sales_ci}</td>
                                            <td>{item.sale_type}</td>
                                            <td>{item.total_price}</td>
                                            <td>{item.sale_date}</td>
                                            <td>
                                                {
                                                    item.sale_type === 'Inventario' ?
                                                        (<><button className='btn btn-outline-primary  ' data-bs-toggle="modal" data-bs-target="#watchModal" onClick={() => dataList(item.sales_ci, item.secuential_bill_id)}> <i className="fas fa-eye" aria-hidden="true" ></i></button>&nbsp;</>
                                                        ) : (
                                                            <><button className='btn btn-outline-primary  ' data-bs-toggle="modal" data-bs-target="#watchModalFree" onClick={() => dataListFree(item.sales_ci, item.secuential_bill_id)}> <i className="fas fa-eye" aria-hidden="true" ></i></button>&nbsp;
                                                            </>
                                                        )
                                                }                                               
                                                <button className='btn btn-outline-danger' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => { setciTotal(item.sales_ci); setsecuentialBillIdTotal(item.secuential_bill_id); settypeSaleTotal(item.sale_type); }}><i className="fas fa-trash-alt" aria-hidden="true"></i></button>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* MODAL VENTA INVENTARIO */}
            <div className="modal fade" id="watchModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg"> {/* Aquí se añade la clase modal-lg */}
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className='col-12 '>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className="form-group">
                                            <h5 className="modal-title" id="exampleModalLabel">Detalle de venta</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">
                            <div className="card-body table-responsive">
                                <table className='table table-hover ' id="dataTable-ord-col"  >
                                    <thead>
                                        <tr>
                                            <th>Cantidad</th>
                                            <th>Producto</th>
                                            <th>Categoría</th>
                                            <th>Precio unitario</th>
                                            <th>Precio total</th>
                                            <th>Fecha</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item) => (
                                            <tr key={item.sales_products_id}>
                                                <td>{item.quantity_prod}</td>
                                                <td>{item.description_product_sale}</td>
                                                <td>{item.description_category}</td>
                                                <td>{item.unitary_price}</td>
                                                <td>{item.total_price_sale}</td>
                                                <td>{item.created_at}</td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="4"><strong>Total:</strong></td>
                                            <td><strong>{total}</strong></td>
                                            {/* <td><strong>{isNaN(total2) ? "0" : total2.toFixed(2)}</strong></td> */}
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Salir</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* MODAL VENTA FREE */}
            <div className="modal fade" id="watchModalFree" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg"> {/* Aquí se añade la clase modal-lg */}
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className='col-12 '>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className="form-group">
                                            <h5 className="modal-title" id="exampleModalLabel">Detalle de venta</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">
                            <div className="card-body table-responsive">
                                <table className='table table-hover ' id="dataTable-ord-col"  >
                                    <thead>
                                        <tr>
                                            <th>Nombre Producto</th>
                                            <th>Precio unitario</th>
                                            <th>Fecha</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataFree.map((item) => (
                                            <tr key={item.sales_products_id}>
                                                <td>{item.description_sale_free}</td>
                                                <td>{item.price_sale_free}</td>
                                                {/* <td>{item.description_product_sale}</td> */}
                                                <td>{item.created_at}</td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="1"><strong>Total:</strong></td>
                                            <td><strong>{totalFree}</strong></td>
                                            {/* <td><strong>{isNaN(total2) ? "0" : total2.toFixed(2)}</strong></td> */}
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Salir</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* MODAL ELIMINAR VENTA TOTAL */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Eliminar</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Está seguro que desea eliminar<b></b>
                        </div>
                        <div className="modal-footer">

                            {/* <button type="button" className="btn btn-primary" onClick={() => DeleteSaleTotal(deleteListSaleFree)} data-bs-dismiss="modal">Aceptar</button> */}
                            <button type="button" className="btn btn-primary" onClick={() => DeleteSaleTotal()} data-bs-dismiss="modal">Aceptar</button>
                            {/* &lt;button type="button" className="btn btn-primary" onClick={() => DeleteSaleTotal()} data-bs-dismiss="modal"&gt;Aceptar&lt;/button&gt; */}

                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  >Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer theme="colored" />

        </div>
    )
}