import React, { useRef } from 'react'
import "./CarouselStyles.css";
import { Link } from 'react-router-dom';


const aliados = [
    { id: 1, src: "./assets/images/carouselTyger/urku2.jpg", name: "URKU ÑAN", city: 'Penipe' },
    { id: 2, src: "./assets/images/carouselTyger/urkunan.jpg", name: "HOSPEDAJE", city: 'Penipe' },
  
];


export const HospedajeCaousel = () => {

    const scrollRef = useRef(null);

    const scrollLeft = () => {
        scrollRef.current.scrollBy({
            left: -300, // Ajusta el valor para determinar la cantidad de desplazamiento
            behavior: "smooth",
        });
    };

    const scrollRight = () => {
        scrollRef.current.scrollBy({
            left: 300, // Ajusta el valor para determinar la cantidad de desplazamiento
            behavior: "smooth",
        });
    };

    return (
        <>
            <div className="horizontal-carousel-container">
                <button className="horizontal-arrow left" onClick={scrollLeft}>
                    <i class="fas fa-chevron-circle-left"></i>
                </button>
                <div className="horizontal-scroll" ref={scrollRef}>
                    {aliados.map((item, index) => (
                        <div className="horizontal-item" key={index}>
                            <Link to={`/tiger-aliado/${item.id}`} style={{ textDecoration: 'none', color: '#212529' }}>
                                <img src={item.src} alt={`Slide ${item.id}`} className="horizontal-image" />
                                <p style={{ textAlign: "center" }}><b>{item.name}</b><br></br>
                                    <b>{item.city}</b>
                                </p>

                            </Link>
                        </div>

                    ))}
                </div>
                <button className="horizontal-arrow right" onClick={scrollRight}>
                    <i class="fas fa-chevron-circle-right"></i>
                </button>
            </div>
        </>

    );


}
