import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ApiUrl } from '../../../../services/ApiRest';

export const WarehouseSelect = ({ warehouse, setwarehouse }) => {

    const [data, setdata] = useState([]);

    const dataList = async () => {
        await axios.get(ApiUrl + 'warehouse-list')
            .then(resp => {
                resp = resp.data;
                console.log(resp);
                setdata(resp);
                //cargamos los datos nuevos
                const warhouseSelected = {
                    "": resp[0].warehouse_id  // Clave vacía con un valor "miValor"
                };
                setwarehouse(warhouseSelected)

            })
            .catch(e => {
                console.log(e);
            })
    }

    const onInputChange = ({ target }) => {
        const { name, value } = target;
        setwarehouse({
            ...warehouse,
            [name]: value
        })
    }

    useEffect(() => {
        dataList();
    }, [])
    return (
        <select
            className="form-select"
            onChange={onInputChange}
            required
            aria-label=".form-select-sm example"
        >
            {/* Verificar si `data` está definido y tiene al menos un elemento */}
            {data && data.length > 0 && (
                <>
                    {/* Primera opción: elemento inicial */}
                    <option value="">Seleccione una opción</option>
                    {/* <option value={data[0].warehouse_id}>{data[0].description}</option> */}
                    {/* Mapeo de los elementos restantes */}
                    {data.map((item) => (
                        <option key={item.warehouse_id} value={item.warehouse_id}>
                            {item.description}
                        </option>
                    ))}
                </>
            )}
        </select>

    )
}
