import React from 'react'
import { ApiUrl } from '../../../services/ApiRest';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';


export const DeleteProduct = ({ productToDelete, dataList }) => {

    const deleteProductInventory = async (id) => {
        await axios.post(ApiUrl + `inventory-delete/${id}`)
            .then(resp => {
                toast.success("Producto eliminado exitosamente de Inventario", { position: toast.POSITION.BOTTOM_RIGHT });
                dataList();
                
            })
            .catch(e => {
                console.log(e);
            });
        // setProductToDelete(null);
    };


    return (
        <div className="modal fade" id="exampleModalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Eliminar</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        Está seguro que desea eliminar
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => deleteProductInventory(productToDelete)} data-bs-dismiss="modal">Aceptar</button>
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  >Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
