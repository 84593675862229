import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles.css';

import reportWebVitals from './reportWebVitals';
import { CampingApp } from './CampingApp';

// Cambiar el título y el favicon dinámicamente
const systemName = process.env.REACT_APP_SYSTEM_NAME || "Camping Chimborazo";
const favicon = document.getElementById("favicon");

// Cambia el título de la página
document.title = systemName;

// Cambia el favicon según el sistema
if (favicon) {
  favicon.href = systemName === "Rantina" ? `${process.env.PUBLIC_URL}/rantina.png` : `${process.env.PUBLIC_URL}/logo512.png`;
}
ReactDOM.render(
    <CampingApp /> ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
