import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ApiUrl } from '../../../services/ApiRest';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
// import { BrowserRouter as Router } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie/es6';
const cookies = new Cookies();
const userId = cookies.get('uid');
// import { BrowserRouter as Router } from 'react-router-dom';
console.log('USER ID principal', userId);

export const SalesProducts = () => {

    const [total, setTotal] = useState(0);
    const [data, setdata] = useState([]);
    const [total2, setTotal2] = useState(0);
    const [totalFree, setTotalFree] = useState(0);
    const [data2, setdata2] = useState([]);
    const [dataFree, setdataFree] = useState([]);
    const [showForm, setShowForm] = useState(true);
    const [ci_total, setciTotal] = useState();
    const [secuentialBillIdTotal, setsecuentialBillIdTotal] = useState();
    const [typeSaleTotal, settypeSaleTotal] = useState();

    const dataListTotalSale = async () => {
        try {
            const response = await axios.get(ApiUrl + `total-list-sale-products`);
            const responseData = response.data;
            setdata2(responseData);
            console.log('DATALIST LISTADO dataListTotalSale:', responseData);

            // Calcular la suma total
            const newTotal2 = responseData.reduce((acc, item) => acc + parseFloat(item.total_price), 0).toFixed(2);
            setTotal2(newTotal2);

            // const script = document.createElement("script");
            // script.src = `/assets/dataTable/dataTable.js`;
            // script.async = true;
            // document.body.appendChild(script);
            // Cargar el script dataTable.js si no está cargado
            if (!document.querySelector("script[src='/assets/dataTable/dataTable.js']")) {
                const script = document.createElement("script");
                script.src = `/assets/dataTable/dataTable.js`;
                script.async = true;
                document.body.appendChild(script);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const dataList = async (ci, secuentialBillId) => {
        try {
            console.log('ci cicicici DATALIST:', ci);
            console.log('ci secuential_bill_id_total DATALIST:', secuentialBillId);
            if (ci && secuentialBillId) {
                const response = await axios.get(ApiUrl + `sale-products-list-total/${ci}/${secuentialBillId}`);
                const responseData = response.data;
                setdata(responseData);
                console.log('DATALIST LISTADO SaleProduct FINAL TOTAL:', responseData);
                const newTotal = responseData.reduce((acc, item) => acc + parseFloat(item.total_price_sale), 0);
                setTotal(newTotal);
            } else {
                console.log('En espera de datos');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const dataListFree = async (ci, secuentialBillId) => {
        try {
            console.log('ci cicicici DATALIST:', ci);
            console.log('ci secuential_bill_id_total DATALIST:', secuentialBillId);

            if (ci && secuentialBillId) {
                const response = await axios.get(ApiUrl + `free-sale-products-list-total/${ci}/${secuentialBillId}`);
                const responseData = response.data;
                setdataFree(responseData);
                console.log('DATALIST LISTADO SaleProduct FINAL TOTAL FREE:', responseData);

                const newTotalFree = responseData.reduce((acc, item) => acc + parseFloat(item.price_sale_free), 0);
                setTotalFree(newTotalFree);
            } else {
                console.log('En espera de datos');
            }

        } catch (error) {
            console.error(error);
        }
    };

    const DeleteSaleTotal = async (ci, secuentialBillId) => {
        // Realizar la actualización del estado en el servidor
        console.log('Valor de ciInput ELIMINAR MODAL:', ci_total);
        console.log('Valor de secuentialBillId ELIMINAR MODAL:', secuentialBillIdTotal);
        console.log('Valor de typeSaleTotal ELIMINAR MODAL:', typeSaleTotal);
        // console.log('Valor de saleProducts Inventario AceptarModal DataList:', passengerStatusValueDataList);
        // console.log('Valor de secuencial final NEW ELIMINAR MODAL:', secuentialBillId);
        // console.log('Valor de secuencial final PROCESS ELIMINAR MODAL:', secuentialBillIdInProcess);
        try {
            const response = await axios.post(ApiUrl + `sale-products-delete-total/${ci_total}/${secuentialBillIdTotal}`);
            const responseData = response.data;
            console.log('DELETE TOTAL', responseData);
            toast.success("Venta eliminada con éxito", { position: toast.POSITION.BOTTOM_RIGHT });
            dataListTotalSale();
        } catch (error) {
            console.error(error);
            toast.error("Error al generar venta", { position: toast.POSITION.BOTTOM_RIGHT });
        }
    };

    useEffect(() => {
        dataList();
    }, []);

    useEffect(() => {
        dataListTotalSale();
    }, []);

    useEffect(() => {
        dataListFree();
    }, []);

    return (
        <div>
            <div className='row'>
                <div className='col-12 '>
                    <div className="card">
                        <div className="card-header">
                            VENTAS
                        </div>
                    </div>
                    {/* <div className="d-flex justify-content-end"> */}
                    <br></br>
                    <div className="d-flex justify-content">
                        {/* <button type="button" className="btn btn-primary btn-lg" data-bs-toggle="modal" href="#exampleModalToggle">Venta inventario</button>&nbsp;&nbsp; */}
                        <Link to={'../ventas-productos-inventario'}>
                            <button type="button" className="btn btn-primary btn-lg mr-2">Venta inventario</button>
                        </Link>
                        <Link to={'../ventas-libre'}>
                            <button type="button" className="btn btn-primary btn-lg">Venta libre</button>
                        </Link>

                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <center><h4><b> TOTAL VENTAS HOY:  {total2} </b></h4></center>
                        </div>
                    </div>

                    <br></br>
                    {showForm && (
                        <div className="card-body">
                            <hr style={{ margin: '10px 0' }} />

                            <div className="card-body table-responsive">
                                <table className='table table-hover ' id="dataTable-ord-col5"  >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Nombre del cliente</th>
                                            <th>Tipo de venta</th>
                                            <th>Total de venta</th>
                                            <th>Fecha</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data2.map((item, index) => (
                                            <tr key={item.sales_products_id}>
                                                <td>{index + 1}</td>
                                                <td>{item.passenge_name}</td>
                                                <td>{item.sale_type}</td>
                                                <td>{item.total_price}</td>
                                                <td>{item.sale_date}</td>
                                                <td>
                                                    {
                                                        item.sale_type === 'Inventario' ?
                                                            (<><button className='btn btn-outline-primary  ' data-bs-toggle="modal" data-bs-target="#watchModal" onClick={() => dataList(item.passengers_ci, item.secuential_bill_id)}> <i className="fas fa-eye" aria-hidden="true" ></i></button>&nbsp;</>
                                                            ) : (
                                                                <><button className='btn btn-outline-primary  ' data-bs-toggle="modal" data-bs-target="#watchModalFree" onClick={() => dataListFree(item.passengers_ci, item.secuential_bill_id)}> <i className="fas fa-eye" aria-hidden="true" ></i></button>&nbsp;
                                                                </>
                                                            )
                                                    }
                                                    <button className='btn btn-outline-danger' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => { setciTotal(item.passengers_ci); setsecuentialBillIdTotal(item.secuential_bill_id); settypeSaleTotal(item.sale_type); }}><i className="fas fa-trash-alt" aria-hidden="true"></i></button>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    )}

                </div>
            </div>

            {/* MODAL VENTA INVENTARIO */}
            <div className="modal fade" id="watchModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg"> {/* Aquí se añade la clase modal-lg */}
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className='col-12 '>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className="form-group">
                                            <h5 className="modal-title" id="exampleModalLabel">Detalle de venta</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">
                            <div className="card-body table-responsive">
                                <table className='table table-hover ' id="dataTable-ord-col"  >
                                    <thead>
                                        <tr>
                                            <th>Cantidad</th>
                                            <th>Producto</th>
                                            <th>Categoría</th>
                                            <th>Precio unitario</th>
                                            <th>Precio total</th>
                                            <th>Fecha</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item) => (
                                            <tr key={item.sales_products_id}>
                                                <td>{item.quantity_prod}</td>
                                                <td>{item.description_product_sale}</td>
                                                <td>{item.description_category}</td>
                                                <td>{item.unitary_price}</td>
                                                <td>{item.total_price_sale}</td>
                                                <td>{item.created_at}</td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="4"><strong>Total:</strong></td>
                                            <td><strong>{total}</strong></td>
                                            {/* <td><strong>{isNaN(total2) ? "0" : total2.toFixed(2)}</strong></td> */}
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Salir</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* MODAL VENTA FREE */}
            <div className="modal fade" id="watchModalFree" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg"> {/* Aquí se añade la clase modal-lg */}
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className='col-12 '>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className="form-group">
                                            <h5 className="modal-title" id="exampleModalLabel">Detalle de venta</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">
                            <div className="card-body table-responsive">
                                <table className='table table-hover ' id="dataTable-ord-col"  >
                                    <thead>
                                        <tr>
                                            <th>Nombre Producto</th>
                                            <th>Precio unitario</th>
                                            <th>Fecha</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataFree.map((item) => (
                                            <tr key={item.sales_products_id}>
                                                <td>{item.description_sale_free}</td>
                                                <td>{item.price_sale_free}</td>
                                                {/* <td>{item.description_product_sale}</td> */}
                                                <td>{item.created_at}</td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="1"><strong>Total:</strong></td>
                                            <td><strong>{totalFree}</strong></td>
                                            {/* <td><strong>{isNaN(total2) ? "0" : total2.toFixed(2)}</strong></td> */}
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Salir</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* MODAL ELIMINAR VENTA TOTAL */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Eliminar</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Está seguro que desea eliminar<b></b>
                        </div>
                        <div className="modal-footer">

                            {/* <button type="button" className="btn btn-primary" onClick={() => DeleteSaleTotal(deleteListSaleFree)} data-bs-dismiss="modal">Aceptar</button> */}
                            <button type="button" className="btn btn-primary" onClick={() => DeleteSaleTotal()} data-bs-dismiss="modal">Aceptar</button>
                            {/* &lt;button type="button" className="btn btn-primary" onClick={() => DeleteSaleTotal()} data-bs-dismiss="modal"&gt;Aceptar&lt;/button&gt; */}

                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  >Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer theme="colored" />

        </div>
    )
}